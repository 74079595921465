import { createSlice } from '@reduxjs/toolkit';
import { ar } from 'date-fns/locale';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';



const initialState = {
    isLoading: false,    
    error: null,
    cargoRoutes: [],
    cargoRoute: null,
    dicStates: [],
    dicPidprAll: [],
    dicStationsUkr: [],
    dicContracts: [],
    activeStep: 0,
    maxActiveStep: 0,

    isOpenCargoRouteModal: false,
    isOpenCargoRouteViewModal: false,

    cargoGroups: [],
    childGroups: []
  };

const slice = createSlice({
    name: 'cargoRoute',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
        state.isLoading = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
        setDicStates(state, action) {
          state.dicStates = action.payload;
        },
        setCargoRoutes(state, action) {
            state.cargoRoutes = action.payload;
        },        
        setCargoRoute(state, action) {
            state.cargoRoute = action.payload;
        },
        setDicPidprAll(state, action) {
            state.dicPidprAll = action.payload;
        },      
        setDicStationsUkr(state, action) {
            state.dicStationsUkr = action.payload;
        }, 
        setDicContracts(state, action) {
            state.dicContracts = action.payload;
        }, 
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },          
        setOpenCargoRouteModal(state, action) {
            state.isOpenCargoRouteModal = action.payload;
        },               
        setOpenCargoRouteViewModal(state, action) {
            state.isOpenCargoRouteViewModal = action.payload;
        },
        setMaxActiveStep(state, action) {
          state.maxActiveStep = action.payload;
        },                       
        setCargoGroups(state, action) {
            state.cargoGroups = action.payload;
        },
        setChildGroups(state, action) {
          state.childGroups = action.payload;
      },
        }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,    
    hasError,    
    setDicStates,
    setCargoRoutes,
    setCargoRoute,
    setDicPidprAll,
    setDicStationsUkr,
    setDicContracts, 
    setActiveStep,
    setMaxActiveStep,  

    setOpenCargoRouteModal,
    setOpenCargoRouteViewModal,

    setCargoGroups,
    setChildGroups
  } = slice.actions;

// ---------------------------------
export const openRouteViewModal = async (idRoute) => {
  getCargoRoute(idRoute);
  dispatch(setOpenCargoRouteViewModal(true));
};

export const closeRouteViewModal = async () => { 
  dispatch(setOpenCargoRouteViewModal(false));
};

// --------класифікатор станів
export const getDicStates = async () => {  
    await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=status&all=true`)
    .then(res => {
      if(res.status === 200) {
        const newDataLabel = [];        
        res.data.forEach(item => { 
          newDataLabel.push({'value': item.value, 'label': item.title})                  
        });         
        dispatch(setDicStates(newDataLabel));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
  };
  
  // --------Реєстр маршрутів
  export const getCargoRoutes = async () => {
    dispatch(setLoading(true));       
    await axios.get(`api-vantazh/contracts/Route?withGroups=false`)
      .then(res => {
        if(res.status === 200) {  
          console.log('getCargoRoutes', res.data);
          dispatch(setCargoRoutes(res.data));                               
        }
        else {          
          throw new Error(res.statusText || res.status);          
        }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })    
  .finally(() => dispatch(setLoading(false)) );
}

// --------видалення маршруту
export const deleteCargoRoute = (idRoute, nameRoute) => {
    showConfirmationModal({
      isOpen: true,
      title: i18n.t('service.cargoRoutes.titleDelete'),
      content: `${i18n.t('service.cargoRoutes.answerDelete')} ${nameRoute}?`,
      onSubmit: () => submitDelete(idRoute),
      onCancel: () => showConfirmationModal({ isOpen: false })
    });
  };
  
  const submitDelete = async (idRoute) => { 
    await axios.delete(`api-vantazh/contracts/Route?idRoute=${idRoute}`)
    .then(res => {
      if(res.status === 200) {      
        getCargoRoutes();      
        showConfirmationModal({ isOpen: false });
        toast.success(i18n.t('service.cargoRoutes.deleted'));
        // toast.success(`Маршрут ${nameRoute} успішно видалено.`);
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }) 
  };

// --------Маршрут (новий)

export const getNewCargoRoute = async () => {
    dispatch(setCargoRoute(null));  
  };

export const getCargoGroupsForNewRoute = async () => {
  dispatch(setLoading(true)); 
  /*  
  const data = `" select g_source.id_group as idGroup   ` +
  ` , a.concatObj as concatObj                          ` +
  ` , a.NameRoute as nameRoute                          ` +
  ` , a.NameCarriers as nameCarriers                    ` +
  ` , a.codeStationFrom as codeStationFrom              ` +
//  ` , a.nameStationFrom as nameStationFrom              ` +
  ` , a.codeStationTo as codeStationTo                  ` +
//  ` , a.nameStationTo as nameStationTo                  ` +
  ` , a.codeAdmStationTo as codeAdmStationTo            ` +
  ` , CONCAT(g_source.name_order, '(Id ', g_source.id_group, ')') as tidGroup   ` +
  ` , g_source.name_order as comment                    ` +
  ` , CONCAT(etsnv.name, '(', m.etsnv, ')') as cargo    ` +
  ` , g_source.group_weight as weight                   ` +
  ` , g_source.count_vag as countCar                    ` +
//  ` , g_source.date_beg_plan as dateBeg                 ` +     
  ` , p.date_beg_cargo as dateBeg                       ` +     
  ` , a.duration as duration                            ` +
//  ` , m.date_end as dateEnd                             ` +     
  ` , p.date_end_cargo as dateEnd                         ` +   
  ` , g_source.id_contract as idContract                ` +
  ` , m.tid_contract as tidContract                     ` +
  ` , g_source.id_cargo as idPart                       ` +
//  ` , p.name_part as namePart                           ` +   
  ` , g_source.id_route as idRoute                      ` +
  ` from IC.Cargo_Group g_source                        ` +
  ` INNER JOIN                                          ` +
  ` (SELECT g.id_group, group_concat(CONCAT(sm.id_rlc, '_', sc.id_carrier) ORDER BY sc.id_carrier) as concatObj   ` +
  `  , CONCAT(st1.display_info, ' - ', st2.display_info) as NameRoute                                             ` +
  `  , group_concat( CONCAT(' ', carriers.display_info) ORDER BY sc.id_carrier) as NameCarriers    ` +
  `  , rm.send_station as codeStationFrom               ` +
//  `  , st1.display_info as nameStationFrom              ` +     
  `  , rm.rec_station as codeStationTo                  ` +
//  `  , st2.display_info as nameStationTo                ` +     
  `  , st2.code_adm as codeAdmStationTo                 ` +
  `  , (SELECT SUM(llg_services.service_duration) from IC.Slc_Services llg_services WHERE llg_services.id_slc = sm.id_slc) duration   ` +
  ` FROM IC.Cargo_Group g                                                                                         ` +
  ` INNER JOIN IC.Slc_Main sm on sm.id_group = g.id_group and sm.type_slc=2                                       ` +
  ` INNER JOIN IC.Slc_Carrier sc on sm.id_slc = sc.id_slc and sc.id_carrier IS NOT null                           ` +
  ` INNER JOIN IC.Rlc_Main rm on rm.id_rlc = sm.id_rlc                                                            ` +
  ` INNER JOIN views.V_Stations st1 on st1.code_station = rm.send_station                                         ` +
  ` INNER JOIN views.V_Stations st2 on st2.code_station = rm.rec_station                                          ` +
  ` INNER JOIN views.V_Ekbs_Carriers carriers on carriers.id_vsp = sc.id_carrier                                  ` +
  ` GROUP BY g.id_group, NameRoute, rm.send_station, st1.display_info, rm.rec_station, st2.display_info, st2.code_adm, duration) a    ` +
  ` ON                                                                                                            ` +
  ` a.id_group = g_source.id_group                                                                                ` +
  ` INNER JOIN IC.Cargo_Part p on p.id_cargo = g_source.id_cargo                                                  ` +   
  ` INNER JOIN IC.Cargo_Main m on m.id_contract = g_source.id_contract and m.date_end >= sysdate()                ` +
  ` INNER JOIN nsi.E_TV_ETSNV etsnv on etsnv.code = m.etsnv                                                       ` +
  ` WHERE g_source.id_route is null                                                                               ` +  
  ` order by a.concatObj, p.date_beg_cargo, g_source.id_group "`;
  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
  */ 
  await axios.get(`api-vantazh/contracts/RouteGroups` ) 
    .then(res => {
    if(res.status === 200) {
       console.log('getNewCargoRoute groupsForRoute', res.data);  
       if (res.data && res.data.length > 0) {
        const uniqueRoute = getUniqueRoute(res.data);
        // console.log('uniqueRoute', uniqueRoute);  
        const routes = [...uniqueRoute];
        res.data.forEach(item => {
          const newItem = {...item};
          newItem.hierarchy = [item.concatObj, item.idGroup];          
          routes.push(newItem);
        });
        console.log('routes', routes); 
        dispatch(setCargoGroups(routes));
        /*
        let routes = [...uniqueRoute];
        routes = routes.map(route => {
          const newRoute = {...route};
          const groups=res.data.filter(x => x.concatObj === route.concatObj);
          newRoute.groups = groups;
          return newRoute;
        });  
        console.log('routes', routes); 
        dispatch(setCargoRoute(routes));
        */
       }
       else  dispatch(setCargoGroups([])); 
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      // console.log(error);
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })  
    .finally(() => dispatch(setLoading(false)) );
    
};
const getUniqueRoute = (data) => {
  const result = [];
  const list = [];
  data.forEach(item => {
    // const newItem = {...item};
    if (!list.includes(item.concatObj)) {
      list.push(item.concatObj);
      // newItem.hierarchy = [item.concatObj];
      result.push({
        idRoute: null,
        hierarchy: [item.concatObj],   
        idGroup: null,
        concatObj: item.concatObj,
        nameRoute: item.nameRoute,
        nameCarriers: item.nameCarriers,
        codeStationFrom: item.codeStationFrom,
        codeStationTo: item.codeStationTo,
        codeAdmStationTo: item.codeAdmStationTo,       
        comment: '',        
        weight: null,
        countCar: null,
        dateBeg: null,
        dateEnd: null,
        isFull: 0,
        nameSender: item.nameSender,
        nameRecepient: item.nameRecepient,
        ownerVag: item.ownerVag,
        rodVag: item.rodVag,
        cargo: item.cargo
      });
    }
  });
  return result;
};
/*
const getUniqueRoute = (data) => {
  const result = [];
  const list = [];
    data.forEach(item => {      
      if (!list.includes(item.concatObj)) {
        list.push(item.concatObj);
        result.push({          
          concatObj : item.concatObj,
          hierarchy: [item.nameRoute],          
          nameRoute: item.nameRoute,
          comment: '',
          nameCarriers: item.nameCarriers,
          codeStationFrom: item.codeStationFrom,
          codeStationTo: item.codeStationTo,
          codeAdmStationTo: item.codeAdmStationTo,
          weight: 0,
          countCar: 0,
          //isFull: false,
          dateBeg: null,
          dateEnd: null,
          idRoute: null,
        });
      }      
    });
  return result;
};    
*/
/*
// --------Маршрут (новий) API от Гусевой - не используется
export const getNewCargoRoute = async () => {
  await axios.get(`api-vantazh/contracts/GroupM`)
    .then(res => {
      if(res.status === 200) {  
        console.log("getNewCargoRoute", res.data);     
        dispatch(setCargoRoute(res.data));
      }
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }) 
};
*/

// --------Маршрут по idRoute -  API от Гусевой 
export const getCargoRoute = async (idRoute) => { 
  dispatch(setLoading(true)); 
  await axios.get(`api-vantazh/contracts/Route?idRoute=${idRoute}`)
    .then(res => {
      if(res.status === 200) {  
        console.log("getCargoRoute", res.data); 
        console.log("CargoGroups", res.data.group);
        dispatch(setChildGroups(res.data.group));    
        dispatch(setCargoRoute(res.data));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })
    .finally(() => dispatch(setLoading(false)) );
  };

// --------збереження нового маршрута
export const saveNewRoute = async (data) => {
  await axios.post(`api-vantazh/contracts/Route`, data)
    .then(res => {
      if(res.status === 200) {
        console.log('saveNewRoute', res.data);
        dispatch(setChildGroups(res.data.group)); 
        console.log("CargoGroups", res.data.group);  
        dispatch(setCargoRoute(res.data));   
        toast.success(i18n.t('service.cargoRoutes.created'));  
        createNewLLM(res.data);
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};
// --------створення ЛЛМ для нового маршрута
export const createNewLLM = async (data) => {
  await axios.get(`api-vantazh/Slcs/Join/${data.idRoute}?nameSlc=${encodeURIComponent(data.tidRoute)}`)
    .then(res => {
      if(res.status === 200) {
        console.log('createNewLLM', res.data);   
        const clonCurrentRoute = {...data};  
        clonCurrentRoute.idSlc = res.data.idSlc;
        dispatch(setCargoRoute(clonCurrentRoute));
        console.log('routeWithIdSlc', clonCurrentRoute);  
        toast.success(i18n.t('service.cargoRoutes.createdLLM'));        
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження існуючого маршрута (коригування)
export const saveRoute = async (data) => {
  await axios.put(`api-vantazh/contracts/Route`, data)
    .then(res => {
      if(res.status === 200) {
        console.log('saveRoute', res.data);
        dispatch(setChildGroups(res.data.group)); 
        console.log("CargoGroups", res.data.group);  
        dispatch(setCargoRoute(res.data));   
        toast.success(i18n.t('service.cargoRoutes.saved'));  
        
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------Маршрут  по idRoute - мой - не используется

export const getCargoRouteById = async (idRoute) => { 
  dispatch(setLoading(true)); 
  const data = `"SELECT g_source.id_group as idGroup      ` +
  ` , g_source.count_vag as groupCountCar                 ` +
  ` , CONCAT(etsnv.name_${localStorage.getItem('i18nextLng')}, '(', m.etsnv, ')') as cargo      ` +
  ` , g_source.group_weight as groupWeight                ` +
  ` , g_source.date_beg_plan as groupDateBeg              ` +
  ` , m.tid_contract as contract                          ` +
  ` , m.date_end as contractDateEnd                       ` +
  ` , route.id_route as idRoute                           ` +
  ` , route.name_route as nameRoute                       ` +
  ` , route.date_beg_route as routeDateBeg                ` +
  ` , route.date_end_route as routeDateEnd                ` +
  ` , a.nameCarriers as nameCarriers                      ` +
  ` , route.count_vag as routeCountCar                    ` +
  ` , route.is_full as routeIsFull                        ` +
  ` , st1.display_info_${localStorage.getItem('i18nextLng')} as routeStationFrom                ` +
  ` , CONCAT(adm.n_adms_${localStorage.getItem('i18nextLng')}, '(', route.adm_prib, ')/', st2.display_info_${localStorage.getItem('i18nextLng')}) as routeStationTo           ` +
  ` FROM IC.Cargo_Group g_source                                                                  ` +
  ` INNER JOIN                                                                                    ` +
  `   (SELECT g.id_group                                                                          ` +
  `    , group_concat(CONCAT(' ', carriers.display_info_${localStorage.getItem('i18nextLng')}) ORDER BY sc.id_carrier) as nameCarriers  ` +    
  `  FROM IC.Cargo_Group g                                                                        ` +
  `  INNER JOIN IC.Slc_Main sm on sm.id_group = g.id_group                                        ` +
  `  INNER JOIN IC.Slc_Carrier sc on sm.id_slc = sc.id_slc                                        ` + 
  `  INNER JOIN views.V_Ekbs_Carriers carriers on carriers.id_vsp = sc.id_carrier                 ` +  
  `  GROUP BY g.id_group) a                                                                       ` +
  `  ON  a.id_group = g_source.id_group                                                           ` +                          
  ` INNER JOIN IC.Cargo_Route route ON route.id_route = g_source.id_route and route.id_route = ${idRoute}   ` + 
  ` INNER JOIN IC.Cargo_Main m on m.id_contract = g_source.id_contract                            ` + 
  ` INNER JOIN nsi.E_TV_ETSNV etsnv on etsnv.code = m.etsnv                                       ` + 
  ` INNER JOIN views.V_Stations st1 on st1.code_station = route.esr_otpr                          ` + 
  ` INNER JOIN views.V_Stations st2 on st2.code_station = route.esr_prib                          ` + 
  ` INNER JOIN nsi.E_D_ADM adm on adm.k_admi = route.adm_prib                                     `;

  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
  .then(res => {
    if(res.status === 200) {  
      console.log("getCargoRouteById", res.data);  
      if (res.data && res.data.length > 0) {
        const uniqueRoute = getUniqueRoute(res.data);
        const routes = [...uniqueRoute];
        res.data.forEach(item => {
          const newItem = {...item};
          newItem.hierarchy = [item.concatObj, item.idGroup];          
          routes.push(newItem);
        });
        console.log('routes', routes); 
        dispatch(setCargoGroups(routes));

        // dispatch(setCargoRoute(res.data));
      } 
      else  dispatch(setCargoRoute(null));   
      
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })
  .finally(() => dispatch(setLoading(false)) );
};

/*----------------*/

// --------Реєстр груп вагонів 
export const getCargoGroups = async () => {  
  dispatch(setLoading(true));    
  await axios.get(`api-vantazh/contracts/GroupRoute`)
  .then(res => {
    if(res.status === 200) {      
     console.log('getCargoGroups', res.data);         
     dispatch(setCargoGroups( res.data ));                               
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
 })
 .catch((error) => {
   // console.log(error);
   toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
 })    
 .finally(() => dispatch(setLoading(false)) );
};

export const getCargoGroupsByRoute = async (idRoute) => {  
  dispatch(setLoading(true));    
  await axios.get(`api-vantazh/contracts/Group`)
  .then(res => {
    if(res.status === 200) {      
     console.log('getCargoGroupsByRoute', res.data);         
     dispatch(setCargoGroups( res.data ));                               
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
 })
 .catch((error) => {
   // console.log(error);
   toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
 })    
 .finally(() => dispatch(setLoading(false)) );
};

export const getCargoRouteMaxActiveStep = async (idSlc) => { 

  if (!idSlc) {
    dispatch(setMaxActiveStep(1));
    return;
  } 

  dispatch(setLoading(true));    
  await axios.get(`api-vantazh/Slcs/Step/${idSlc}`)
  .then(res => {
    if(res.status === 200) {      
     console.log('getCargoRouteActiveStep', res.data);         
     dispatch(setMaxActiveStep( res.data ));                               
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
 })
 .catch((error) => {
   toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
 })    
 .finally(() => dispatch(setLoading(false)) );
};
