import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// mesplan
import ReestrList from '../pages/mesplan/ReestrList';
import SoglReestrList from '../pages/mesplan/SoglReestrList';
import ElzReestrList from '../pages/mesplan/ElzList';
import MesplanSettings from '../pages/mesplan/Settings';
// mesplan handbook
import HandBook from '../pages/mesplan/HandBook';
import GetShippers from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetShippers';
import GetStationNazn from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetStationNazn';
import GetTransPlan from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetTransPlan';
import GetPpMesplan from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetPpMesplan';
import GetAdm from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetAdm';
import GetConsignees from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetConsignees';
import GetCountry from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetCountry';
import GetEkspedFormir from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetEkspedFormir';
import GetEkspedUkr from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetEkspedUkr';
import GetElevator from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetElevator';
import GetETSNV from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetETSNV';
import GetMarshrutEsr from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetMarshrutEsr';
import GetNomenklat from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetNomenklat';
import GetNomenPEK from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetNomenPEK';
import GetNZagr from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetNZagr';
import GetPerevizn from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetPerevizn';
import GetPoluchatelPort from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetPoluchatelPort';
import GetPort from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetPort';
import GetProzzoroLot from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetProzzoroLot';
import GetProzzoroLotDlg from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetProzzoroLotDlg';
import GetSobstnvVag from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetSobstnvVag';
import GetVagRod from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetVagRod';
import GetZD from '../sections/@dashboard/services/mesplan/handBook/InfoComponents/GetZD';
//
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/ekbs')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'ekbs',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'profiles', element: <Profiles /> },

        { path: 'app', element: <GeneralApp /> },
        { path: 'cabinet', element: <StartCabinet /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        { path: 'projects',   // http://localhost:3032/ekbs/services/projects/proj_reestr
          children: [
            { element: <Navigate to="/ekbs/services" replace />, index: true },
            { path: 'projects/proj_reestr', element: <ProjectReestr /> },
          ] },
        {
          path: 'services',
          children: [
            { element: <Navigate to="/ekbs/services" replace />, index: true },
            { path: 'startCabinet', element: <StartCabinet /> },
            { path: 'startCabinet/startCabinet_view', element: <StartCabinet /> },
            { path: 'events', element: <EventPage /> }, // http://localhost:3032/ekbs/services/events/events_view
            { path: 'resources', element: <AdminResources /> },
            { path: 'events/events_view', element: <EventPage /> },
            { path: 'banking', element: <EventPage /> },
            { path: 'banking/valuta', element: <ValutaPage /> },
            { path: 'sigfox', element: <SigfoxMapSection idLayer={3} /> },
            { path: 'sigfox/sigfox_admin_zona', element: <SigfoxZonaPage idLayer={10} /> },
            { path: 'sigfox/sigfox_map', element: <SigfoxMapSection idLayer={3} /> },
            { path: 'sigfox/sigfox_admin_map', element: <SigfoxMapSection idLayer={5} /> },
            { path: 'sigfox/sigfox_trackers', element: <SigfoxTrackersSection /> },
            { path: 'sigfox/sigfox_admin_trackers', element: <SigfoxAdminTrackersSection /> },            
            // { path: 'infovag', element: <InfoVag /> }, // http://localhost:3032/ekbs/services/infovag/infovag_view
            { path: 'vagoni/wagons-in-use', element: <InfoVag /> }, // http://localhost:3032/ekbs/services/infovag/infovag_view
            { path: 'vagoni/my-wagons', element: <InfoVagMyWagons /> }, // http://localhost:3032/ekbs/services/infovag/infovag_view
            { path: 'vagoni/vaginform', element: <InfoVagUZ /> }, // http://localhost:3032/ekbs/services/vagoni/vaginform
            { path: 'wagons-in-use/:id', element: <InfoVagDetailsPage /> },
            { path: 'my-wagons/:id', element: <InfoVagDetailsPage /> },
            { path: 'map', element: <MapSection /> }, // http://localhost:3032/ekbs/services/map/map_elevators
            { path: 'map/map_elevators', element: <MapSection /> },
            { path: 'apppd/apppd_doc', element: <Pdocument /> },
            { path: 'apppd/apppd_vp', element: <VPdocument /> },
            { path: 'apppd/apppd_attached', element: <AttachedDocuments /> },
            { path: 'tarifs', element: <TarifCalc /> }, // http://localhost:3032/ekbs/services/tarifs/tariff_view
            { path: 'tarifs/tariff_view', element: <TarifCalc /> },
            { path: 'tarifs/info', element: <TarifInfo /> },
            { path: 'rcalendar', element: <CalendarRI /> }, 
            { path: 'rcalendar/rcalendar_view', element: <CalendarRI /> }, 
            { path: 'rcalendar/rcalendar_resource', element: <CalendarMR /> },
            { path: 'rcalendar/rcalendar_analise', element: <CalendarAnalisePage /> },
            // { path: 'formulyar', element: <Formulyars /> }, // http://localhost:3032/ekbs/services/formulyar/view_formulyar
            // { path: 'formulyar/view_formulyar', element: <Formulyars /> },
            // { path: 'formulyar/:id/:name', element: <FormulyarDetails /> },
            { path: 'contracts', element: <Contracts /> },
            { path: 'contracts/:filterField/:filterValue', element: <Contracts /> },
            { path: 'contracts/:filterField/:filterValue/:linkOperator/:filterField2/:filterValue2', element: <Contracts /> },
            { path: 'contracts/CONTRACTS_VIEW', element: <Contracts /> },
            // { path: 'contracts/CONTRACT_NEW', element: <ContractPage /> },
            // { path: 'contracts/CONTRACT_NEW', element: <CargoParts /> },
            { path: 'contract/new', element: <ContractPage /> },
            { path: 'contract/:id', element: <ContractPage /> },
            { path: 'contracts/:id/:name', element: <ContractDetails /> },
            { path: 'contract/:id/:name', element: <Contract /> },
            { path: 'CARGOPARTS', element: <CargoParts /> },
            { path: 'CARGOPARTS/CARGOPARTS_VIEW', element: <CargoParts /> },
            // { path: 'CARGOPARTS/CARGOPART_NEW', element: <CargoPartPage /> },
            { path: 'cargoParts/:contract/:name/:type', element: <CargoParts /> },
            // { path: 'cargoParts/:contract/:name', element: <CargoParts /> },
            { path: 'cargoPart/:contract/new', element: <CargoPartPage /> },
            { path: 'cargoPart/:contract/new/:name', element: <CargoPartPage /> },
            { path: 'cargoPart/:contract/:id', element: <CargoPartPage /> },
            { path: 'cargoPart/:id', element: <CargoPartPage /> },
            { path: 'cargoPart/new', element: <CargoPartPage /> },
            { path: 'cargoGroups', element: <CargoGroups /> }, 
            { path: 'cargoGroups/CARGOGROUPS_VIEW', element: <CargoGroups /> },  // https://dev.vantazhplus.co.ua/ekbs/services/cargogroups/cargogroups_view
            // { path: 'cargoGroups/CARGOGROUP_NEW', element: <CargoGroupPage /> },
            { path: 'cargoGroups/:part/:name', element: <CargoGroups /> },
            { path: 'cargoGroup/:part/new', element: <CargoGroupPage /> },
            { path: 'cargoGroup/:part/new/:name', element: <CargoGroupPage /> },
            { path: 'cargoGroup/:part/:id', element: <CargoGroupPage /> },
            { path: 'cargoGroup/:id', element: <CargoGroupPage /> },
            { path: 'cargoGroup/new', element: <CargoGroupPage /> },
            { path: 'cargoGroup/:id/copy', element: <CargoGroupPage /> },
            { path: 'cargoGroup/:part/:id/copy', element: <CargoGroupPage /> },

            { path: 'cargoGroups/CARGOGROUPS_SLC', element: <GroupChains /> }, // https://dev.vantazhplus.co.ua/ekbs/services/cargogroups/cargogroups_slc
            { path: 'groupChains/:group/:name', element: <GroupChains /> },
            { path: 'groupChain/:id', element: <GroupChainPage /> },
            { path: 'groupChain/:group/:id', element: <GroupChainPage /> },
            { path: 'groupChain/new', element: <GroupChainPage /> },
            { path: 'groupChain/:group/new', element: <GroupChainPage /> },
            
            { path: 'cargoRoutes', element: <CargoRoutes /> }, // https://dev.vantazhplus.co.ua/ekbs/services/cargoroutes/cargoroutes_view
            { path: 'cargoRoutes/cargoroutes_view', element: <CargoRoutes /> },
            // { path: 'cargoRoutes/cargoroutes_SRRM', element: <CargoRoutes /> },
            // { path: 'cargoRoutes/cargoroutes_ZAYAVKA', element: <CargoRoutes /> },
            // { path: 'cargoRoutes/cargoroutes_GRAFIK', element: <CargoRoutes /> },
            { path: 'cargoRouteBuild/new', element: <CargoRouteBuildPage /> },
            { path: 'cargoRouteBuild/:id', element: <CargoRouteBuildPage /> },
            { path: 'cargoRoute/new', element: <CargoRoutePage /> },
            { path: 'cargoRoute/:id', element: <CargoRoutePage /> },
            
            
            { path: 'regulators', element: <RegulatorChains /> },
            { path: 'regulators/REGULATORS_VIEW', element: <RegulatorChains /> },
            // { path: 'regulators/REGULATOR_NEW', element: <RegulatorChainPage /> },
            { path: 'regulatorChain/new', element: <RegulatorChainPage /> },
            { path: 'regulatorChain/:id', element: <RegulatorChainPage /> },
            // { path: 'regulatorChain/:id/:name/:user', element: <RegulatorChainDetails /> },
            { path: 'logisticChains', element: <CargoRoutes /> },
            { path: 'logisticChains/LOGISTICCHAINS_SRRM', element: <CargoRoutes /> },
            { path: 'logisticChains/LOGISTICCHAINS_ZAYAVKA', element: <CargoRoutes /> },
            { path: 'logisticChains/LOGISTICCHAINS_GRAFIK', element: <CargoRoutes /> },

            { path: 'offers', element: <Offers /> },
            { path: 'offers/OFFERS_VIEW', element: <Offers /> },

            { path: 'exeMonitoring', element: <ExeMonitoring /> },
            { path: 'exeMonitoring/:contract/:name', element: <ExeMonitoring /> },

            { path: 'exeServices', element: <ExeServices /> },
            { path: 'exeServices/exeServices_view', element: <ExeServices /> },
            
            { path: 'provisionservices', element: <ServicesPlan /> },
            { path: 'provisionservices/provisionservices_plan', element: <ServicesPlan /> },

            { path: 'profiles/adm_cabinet', element: <AdmCabinet /> }, 
            { path: 'profiles/adm_users', element: <AdmUsers /> },
            { path: 'profiles/structure_enterprise', element: <AdmStructure /> },
            { path: 'auction', element: <Auction /> },
            { path: 'auction/stand_view', element: <Auction /> },
            
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // =======  visual page ===============
            // http://localhost:3032/ekbs/services/visual/visual_contract_main
            { path: 'visual/visual_contract_main', element: <VisualMainPage /> },
            { path: 'visual/ganta/:id', element: <GantaUZ /> },
            { path: 'visual/contracts', element: <ContractsList /> },
            { path: 'visual/vcontracts', element: <VContract /> },
            { path: 'visual/vstykrosklad/:id', element: <GantaUZ /> },
            { path: 'visual/vstykrosklad', element: <GantaUZ /> },
            { path: 'visual/contract/:id', element: <ContractItem /> },
            { path: 'visual/route/:id', element: <RouteInfo /> },
            { path: 'visual/plan', element: <Plan /> },
            { path: 'visual/vperevezenya', element: <Plan /> },
            { path: 'visual/plan/:id', element: <Plan /> },
            { path: 'visual/gu-12', element: <GU12 /> },
            { path: 'visual/gu-12/:id', element: <GU12 /> },
            { path: 'visual/vcatalog', element: <VRoute /> },
            
            { path: 'auction/stand_request', element: <Auction /> },
            { path: 'auction/vagons', element: <AuctionVagons /> },
            // { path: 'auction/my-enteprises', element: <AuctionByMyEnterprise /> },
            // { path: 'auction/other-enteprises', element: <AuctionByOtherEnterprise /> },
            // { path: 'auction/other-enteprises/:id', element: <AuctionByOtherEnterprise /> },
            // { path: 'auction/supply-demand', element: <SupplyDemand /> },

            { path: 'projects/proj_reestr', element: <ProjectReestr /> },
            { path: 'projects/proj_nar', element: <ProjectReestr /> },
            { path: 'projects/proj_nar', element: <ProjectReestr /> },

            // { path: 'order/:id', element: <OrderPage /> }
            // http://localhost:3032/ekbs/services/analiticdov/infovag_disl
            // ANALITIC
            { path: 'analiticdov/infovag_disl', element: <InfoVagDislocation /> }, // http://localhost:3032/ekbs/services/infovag/infovag_view
            { path: 'analiticdov/infovag_disl/:id', element: <InfoVagDislocationByStation /> },
            {
              path: 'e-toloka',
              children: [
                { element: <Navigate to="/ekbs/services/e-toloka/main" replace />, index: true },
                { path: 'main', element: <TolokaMainPage /> },
                { path: 'product/:id', element: <TolokaProductDetails /> },
                { path: 'list', element: <TolokaProductList /> },
                { path: 'product/new', element: <TolokaProductCreate /> },
                { path: 'product/:id/edit', element: <TolokaProductCreate /> },
                { path: 'checkout', element: <TolokaCheckout /> },
                { path: 'checkout/:id', element: <TolokaCheckout /> },
                { path: 'invoice', element: <TolokaInvoice /> },
                { path: 'order', element: <TolokaOrderList /> },
              ],
            },
            { path: 'documents/constructor', element: <DocumentsConstructor /> },
            { path: 'documents/archive', element: <DocumentsArchive /> },
            { path: 'documents/archive/:id', element: <DocumentItem /> }

          ]
        },
		{
          path: 'mesplan',
          children: [
            { element: <Navigate to="/ekbs/mesplan" replace />, index: true },
            { path: 'reestr', element: <ReestrList /> },    
            { path: 'soglReestr', element: <SoglReestrList /> },    
            { path: 'elzReestr', element: <ElzReestrList /> },  
            { path: 'mesplanSettings', element: <MesplanSettings /> },    
            { path: 'handbookMesplan', element: <HandBook /> },            
          ]
        },
        {
          path: 'handBook',
          children: [
            { element: <Navigate to="/ekbs/handBook" replace />, index: true },
            { path: 'getShippers', element: <GetShippers /> },
            { path: 'getStationNazn', element: <GetStationNazn /> },
            { path: 'getTransPlan', element: <GetTransPlan /> },
            { path: 'get_pp_mesplan', element: <GetPpMesplan /> },
            { path: 'getAdm', element: <GetAdm /> },
            { path: 'getConsignees', element: <GetConsignees /> },
            { path: 'getCountry', element: <GetCountry /> },
            { path: 'getEkspedFormir', element: <GetEkspedFormir /> },
            { path: 'getEkspedUkr', element: <GetEkspedUkr /> },
            { path: 'getElevator', element: <GetElevator /> },
            { path: 'getETSNV', element: <GetETSNV /> },
            { path: 'getETSNV', element: <GetETSNV /> },
            { path: 'getNomenklat', element: <GetNomenklat /> },
            { path: 'getNomenPEK', element: <GetNomenPEK /> },
            { path: 'getNZagr', element: <GetNZagr /> },
            { path: 'getPerevizn', element: <GetPerevizn /> },
            { path: 'getPoluchatelPort', element: <GetPoluchatelPort /> },
            { path: 'getPort', element: <GetPort /> },
            { path: 'getProzzoroLot', element: <GetProzzoroLot /> },
            { path: 'getProzzoroLotDlg', element: <GetProzzoroLotDlg /> },
            { path: 'getSobstnvVag', element: <GetSobstnvVag /> },
            { path: 'getVagRod', element: <GetVagRod /> },
            { path: 'getZD', element: <GetZD /> },
            { path: 'getMarshrutEsr', element: <GetMarshrutEsr /> },
          ]
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/ekbs/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/ekbs/user/profile" replace />, index: true },
            { path: 'profile', element: <Profiles /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'bugs', element: <BugsList /> },
            { path: 'agreements', element: <Agreements /> },
            { path: 'partner/:id', element: <Partners /> },
            { path: 'enterprises', element: <EnterprisesList />},
            { path: 'enterprise/:id', element: <Enterprise />},
            { path: 'mypublications', element: <MyPublications />},
            { path: 'mypublications/:id', element: <MyPublicationsItem />},
            { path: 'mypublications/new', element: <NewPublication />},
            { path: 'mypublications/edit/:id', element: <EditPublication />},
            { path: 'all-publications', element: <AllPublications />},
            { path: 'all-publications/:id', element: <AllPublicationsItem />},
          ],
        },
        {
          path: 'pidpr',
          children: [
            { element: <Navigate to="/ekbs/pidpr" replace />, index: true },
            { path: 'agreements', element: <Agreements /> },
            { path: 'partner/:id', element: <Partners /> },
            { path: 'enterprises', element: <EnterprisesList />},
            { path: 'enterprise/:id', element: <Enterprise />},
          ],
        },
        {
          path: 'posts',
          children: [
            { element: <Navigate to="/ekbs/posts" replace />, index: true },
            { path: 'mypublications', element: <MyPublications />},
            { path: 'mypublications/:id', element: <MyPublicationsItem />},
            { path: 'mypublications/new', element: <NewPublication />},
            { path: 'mypublications/edit/:id', element: <EditPublication />},
            { path: 'all-publications', element: <AllPublications />},
            { path: 'all-publications/:id', element: <AllPublicationsItem />},
          ],
        },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/ekbs/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:idrec', element: <BlogPost /> },
        //     { path: 'new-post', element: <BlogNewPost /> },
        //   ],
        // },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/ekbs/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'trackers', element: <Trackers /> },
        { path: 'founder', element: <Team /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'publications', element: <Publications /> },
        { path: 'publications/:id', element: <SinglePublication /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const StartCabinet = Loadable(lazy(() => import('../pages/dashboard/startCabinet/StartCabinet')));
// const StartCabinet = Loadable(lazy(() => import('../sections/@dashboard/services/startCabinet/StartCabinet')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/template/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/template/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/template/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/template/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/template/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/template/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/template/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/template/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/template/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/template/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/template/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/template/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/template/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/template/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/template/Chat')));
const Mail = Loadable(lazy(() => import('../pages/template/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/template/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/template/Kanban')));

// TOLOKA
const TolokaMainPage = Loadable(lazy(() => import('../pages/dashboard/toloka/main/TolokaMain')));
const TolokaProductList = Loadable(lazy(() => import('../pages/dashboard/toloka/TolokaProductList')));
// const TolokaShop = Loadable(lazy(() => import('../pages/dashboard/toloka/TolokaShop')));
const TolokaProductDetails = Loadable(lazy(() => import('../pages/dashboard/toloka/TolokaProductDetails')));
const TolokaProductCreate = Loadable(lazy(() => import('../pages/dashboard/toloka/TolokaProductCreate')));
const TolokaCheckout = Loadable(lazy(() => import('../pages/dashboard/toloka/TolokaCheckout')));
const TolokaInvoice = Loadable(lazy(() => import('../pages/dashboard/toloka/TolokaInvoice')));
const TolokaOrderList = Loadable(lazy(() => import('../pages/dashboard/toloka/orders/TolokaOrderList')));

// -----------
const DocumentsConstructor = Loadable(lazy(() => import('../sections/@dashboard/services/documentConstructor')));
const DocumentsArchive = Loadable(lazy(() => import('../sections/@dashboard/services/documentsArchive')));
const DocumentItem = Loadable(lazy(() => import('../sections/@dashboard/services/documentsArchive/documentItem')));
// -----------

// EKBS
const BugsList = Loadable(lazy(() => import('../sections/@dashboard/user/bugs')));
const Agreements = Loadable(lazy(() => import('../sections/@dashboard/user/agreements/Agreements')));
const Partners = Loadable(lazy(() => import('../sections/@dashboard/user/partners')));
const Profiles = Loadable(lazy(() => import('../sections/@dashboard/profiles/Profiles')));
const TarifCalc = Loadable(lazy(() => import('../sections/@dashboard/services/tarifs/TarifCalc')));
const TarifInfo = Loadable(lazy(() => import('../sections/@dashboard/services/tarifs/TarifInfo')));
const MapSection = Loadable(lazy(() => import('../sections/@dashboard/services/map/Map')));
const Pdocument = Loadable(lazy(() => import('../sections/@dashboard/services/pdocument/Pdocument')));
const VPdocument = Loadable(lazy(() => import('../sections/@dashboard/services/pdocument/VPdocument')));
const AttachedDocuments = Loadable(lazy(() => import('../sections/@dashboard/services/pdocument/AttachedDoc')));
const AdminResources = Loadable(lazy(() => import('../pages/dashboard/services/resources/AdminResources')));
// const OrderPage = Loadable(lazy(() => import('../sections/@dashboard/services/order')));
const InfoVag = Loadable(lazy(() => import('../sections/@dashboard/services/infovag/InfoVag')));
const InfoVagMyWagons = Loadable(lazy(() => import('../sections/@dashboard/services/infovag/MyWagons')));
const InfoVagUZ = Loadable(lazy(() => import('../sections/@dashboard/services/infovag/InfoVagonsUz')));
const InfoVagDetailsPage = Loadable(lazy(() => import('../sections/@dashboard/services/infovag/InfoVagDetailsPage')));
const InfoVagDislocation = Loadable(lazy(() => import('../sections/@dashboard/services/analiticdov/dislocation')));
const InfoVagDislocationByStation = Loadable(lazy(() => import('../sections/@dashboard/services/analiticdov/dislocation/InfoByStation')));
const ValutaPage = Loadable(lazy(() => import('../pages/dashboard/services/banking/Valuta')));
const EventPage = Loadable(lazy(() => import('../pages/dashboard/services/events/Events')));
const SigfoxMapSection = Loadable(lazy(() => import('../sections/@dashboard/services/sigfox/Sigfox')));
const SigfoxTrackersSection = Loadable(lazy(() => import('../sections/@dashboard/services/sigfox/SigfoxTrackers')));
const SigfoxAdminTrackersSection = Loadable(lazy(() => import('../sections/@dashboard/services/sigfox/SigfoxAdminTrackers')));
const SigfoxZonaPage = Loadable(lazy(() => import('../sections/@dashboard/services/sigfox/SigfoxZona')));
const Default = Loadable(lazy(() => import('../pages/Default')));
const CalendarRI = Loadable(lazy(() => import('../pages/dashboard/services/calendar/PartnerResources/CalendarRI')));
const CalendarMR = Loadable(lazy(() => import('../pages/dashboard/services/calendar/MyResources/CalendarMR')));
const CalendarAnalisePage = Loadable(lazy(() => import('../pages/dashboard/services/calendar/CalendarAnalise')));

// const Formulyars = Loadable(lazy(() => import('../sections/@dashboard/services/formulyars/Formulyars')));
// const FormulyarDetails = Loadable(lazy(() => import('../sections/@dashboard/services/formulyars/FormulyarDetails')));
const Contracts = Loadable(lazy(() => import('../sections/@dashboard/services/contracts/Contracts')));
const ContractPage = Loadable(lazy(() => import('../sections/@dashboard/services/contracts/ContractPage')));
const ContractDetails = Loadable(lazy(() => import('../sections/@dashboard/services/contracts/ContractDetails')));
const Contract = Loadable(lazy(() => import('../sections/@dashboard/services/contracts/Contract')));
const CargoParts = Loadable(lazy(() => import('../sections/@dashboard/services/cargoParts/CargoParts')));
const CargoPartPage = Loadable(lazy(() => import('../sections/@dashboard/services/cargoParts/CargoPartPage')));
const CargoGroups = Loadable(lazy(() => import('../sections/@dashboard/services/cargoGroups/CargoGroups')));
const CargoGroupPage = Loadable(lazy(() => import('../sections/@dashboard/services/cargoGroups/CargoGroupPage')));
const GroupChains = Loadable(lazy(() => import('../sections/@dashboard/services/groupChains/GroupChains')));  // реєстр ЛЛГ
const GroupChainPage = Loadable(lazy(() => import('../sections/@dashboard/services/groupChains/GroupChainPage')));  // реєстр ЛЛГ
const CargoRoutes = Loadable(lazy(() => import('../sections/@dashboard/services/cargoRoutes/CargoRoutes')));
const CargoRouteBuildPage = Loadable(lazy(() => import('../sections/@dashboard/services/cargoRoutes/CargoRouteBuildPage')));
const CargoRoutePage = Loadable(lazy(() => import('../sections/@dashboard/services/cargoRoutes/CargoRoutePage')));
// const RegulatorChains = Loadable(lazy(() => import('../sections/@dashboard/services/regulators/RegulatorChains/RegulatorChains')));
const RegulatorChains = Loadable(lazy(() => import('../sections/@dashboard/services/regulatorChains/RegulatorChains')));
const RegulatorChainPage = Loadable(lazy(() => import('../sections/@dashboard/services/regulatorChains/RegulatorChainPage')));
// const RegulatorChainDetails = Loadable(lazy(() => import('../sections/@dashboard/services/regulators/RegulatorChains/RegulatorChainDetails')));
const Offers = Loadable(lazy(() => import('../sections/@dashboard/services/offers/Offers')));
const ExeMonitoring = Loadable(lazy(() => import('../sections/@dashboard/services/exeMonitoring/ExeMonitoring')));
const ExeServices = Loadable(lazy(() => import('../sections/@dashboard/services/exeServices/ExeServices')));


const ServicesPlan = Loadable(lazy(() => import('../sections/@dashboard/services/provisionServices/ServicesPlan')));
const AdmCabinet = Loadable(lazy(() => import('../pages/dashboard/services/admcabinet/AdmCabinet')));
const AdmUsers = Loadable(lazy(() => import('../pages/dashboard/services/admcabinet/AdmUsers')));
const AdmStructure = Loadable(lazy(() => import('../pages/dashboard/services/admcabinet/AdmStructure')));
const Auction = Loadable(lazy(() => import('../pages/dashboard/services/auction/Auction')));
const AuctionVagons = Loadable(lazy(() => import('../sections/@dashboard/services/auction/vagons')));
// const AuctionByMyEnterprise = Loadable(lazy(() => import('../sections/@dashboard/services/auction/MyEnterprise')));
// const AuctionByOtherEnterprise = Loadable(lazy(() => import('../sections/@dashboard/services/auction/OtherEnterprises')));
// const SupplyDemand = Loadable(lazy(() => import('../sections/@dashboard/services/auction/SupplyDemand')));
const EnterprisesList = Loadable(lazy(() => import('../sections/@dashboard/user/enterprises/EnterprisesList')));
const Enterprise = Loadable(lazy(() => import('../sections/@dashboard/user/enterprises/Enterprise')));
const MyPublications = Loadable(lazy(() => import('../sections/@dashboard/user/mypublications/index')));
const MyPublicationsItem = Loadable(lazy(() => import('../sections/@dashboard/user/mypublications/myPublicationItem')));
const NewPublication = Loadable(lazy(() => import('../sections/@dashboard/user/mypublications/newPublication')));
const EditPublication = Loadable(lazy(() => import('../sections/@dashboard/user/mypublications/editPublication')));
const AllPublications = Loadable(lazy(() => import('../sections/@dashboard/user/allpublications/index')));
const AllPublicationsItem = Loadable(lazy(() => import('../sections/@dashboard/user/allpublications/AllPublicationsItem')));

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// === visual =====================
const VisualMainPage = Loadable(lazy(() => import('../pages/dashboard/services/visual')));
const GantaUZ = Loadable(lazy(() => import('../sections/@dashboard/services/visual/ganta/gantaUZ')));
const ContractsList = Loadable(lazy(() => import('../sections/@dashboard/services/visual/contracts/ContractsList')));
const ContractItem = Loadable(lazy(() => import('../sections/@dashboard/services/visual/contracts/ContractItem')));
const RouteInfo = Loadable(lazy(() => import('../sections/@dashboard/services/visual/routeInfo')));
const Plan = Loadable(lazy(() => import('../sections/@dashboard/services/visual/plan')));
const GU12 = Loadable(lazy(() => import('../sections/@dashboard/services/visual/gu-12')));
const VContract = Loadable(lazy(() => import('../sections/@dashboard/services/visual/vcatalog/indexContract')));
const VRoute = Loadable(lazy(() => import('../sections/@dashboard/services/visual/vcatalog/indexRoute')));
// Projects
const ProjectReestr = Loadable(lazy(() => import('../pages/dashboard/projects/Reestr')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Team = Loadable(lazy(() => import('../pages/Team')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Trackers = Loadable(lazy(() => import('../pages/Trackers')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Publications = Loadable(lazy(() => import('../pages/Publications')));
const SinglePublication = Loadable(lazy(() => import('../pages/SinglePublication')));
