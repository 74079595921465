import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';

const initialState = {
    isLoading: false,
    error: null,
    isMoved: false,
    groupChains: [],    
    dicStates: [],
    isOpenGroupChainViewModal: false,

    chainMain: null,
    chainMainInfo: null,
    dicCargoGroups: [],
    dicNLL: [],

    sections: [],
    section: null,
    isOpenSectionModal: false,
    dicAdm: [],    
    dicStationsAll: [],
    dicCarriers: [],
    dicPayers: [],

    chainActions: [],
    chainAction: null,
    isOpenActionModal: false,
    actionServices: [],
    actionService: null, 
    isOpenServiceModal: false, 
    
    dicLinkServices: [],
    dicLinkServicesAll: [],
    dicCurrency: [],
    dicUnit: [],
    dicActions: [],

    isOpenServiceExecutorsModal: false,
    executors: [],
    dicAgreements: [],
    dicLLG: [],
};

const slice = createSlice({
    name: 'groupChain',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
        state.isLoading = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },           
        setMoved(state, action) {
            state.isMoved = action.payload;
        },                 
        setGroupChains(state, action) {
        state.groupChains = action.payload;
        },           
        setDicStates(state, action) {
          state.dicStates = action.payload;
        },
        setOpenChainViewModal(state, action) {
          state.isOpenGroupChainViewModal = action.payload;
        },
        setChainMain(state, action) {
          state.chainMain = action.payload;
        },  
        setChainMainInfo(state, action) {
          state.chainMainInfo = action.payload;
        },  
        setDicCargoGroups(state, action) {
          state.dicCargoGroups = action.payload;
        }, 
        setDicNLL(state, action) {
          state.dicNLL = action.payload;
        },         
        setSections(state, action) {
          state.sections = action.payload;
        },
        setSection(state, action) {
          state.section = action.payload;
        }, 
        setSectionModal(state, action) {
          state.isOpenSectionModal = action.payload;
        },   
        setDicAdm(state, action) {
          state.dicAdm = action.payload;
        }, 
        setDicStationsAll(state, action) {
          state.dicStationsAll = action.payload;
        }, 
        setDicCarriers(state, action) {
          state.dicCarriers = action.payload;
        }, 
        setDicPayers(state, action) {
          state.dicPayers = action.payload;
        },         

        setChainActions(state, action) {
        state.chainActions = action.payload;
        },        
        setChainAction(state, action) {
        state.chainAction = action.payload;
        },
        setActionModal(state, action) {
          state.isOpenActionModal = action.payload;
        },   
        setActionServices(state, action) {
          state.actionServices = action.payload;
        }, 
        setActionService(state, action) {
          state.actionService = action.payload;
        }, 
        setServiceModal(state, action) {
          state.isOpenServiceModal = action.payload;
        },
        setDicLinkServices(state, action) {
          state.dicLinkServices = action.payload;
        },
        setDicLinkServicesAll(state, action) {
          state.dicLinkServicesAll = action.payload;
        }, 
        setDicCurrency(state, action) {
          state.dicCurrency = action.payload;
        }, 
        setDicUnit(state, action) {
          state.dicUnit = action.payload;
        },
        setDicActions(state, action) {
          state.dicActions = action.payload;
        },
        setOpenServiceExecutorsModal(state, action) {
          state.isOpenServiceExecutorsModal = action.payload;
        }, 
        setExecutors(state, action) {
          state.executors = action.payload;
        }, 
        setDicAgreements(state, action) {
          state.dicAgreements = action.payload;
        }, 
        setDicLLG(state, action) {
          state.dicLLG = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    hasError,
    setMoved,
    setGroupChains,     
    setDicStates,
    setOpenChainViewModal,
    
    setChainMain,
    setChainMainInfo,
    setDicCargoGroups,
    setDicNLL,
    
    setSections,
    setSection,
    setSectionModal,
    setDicAdm,
    setDicStationsAll,
    setDicCarriers,
    setDicPayers,

    setChainActions,
    setChainAction,
    setActionModal,
    setActionServices, 
    setActionService, 
    setServiceModal, 
    
    setDicLinkServices,
    setDicLinkServicesAll,
    setDicCurrency,
    setDicUnit,
    setDicActions,

    setOpenServiceExecutorsModal,
    setExecutors,
    setDicAgreements,
    setDicLLG

} = slice.actions;

// --------Реєстр логістичних ланцюжків групи вагонів
export const getGroupChains = async () => {
    dispatch(setLoading(true));
    await axios.get(`api-vantazh/Slcs/LLG`)
    .then(res => {
      if(res.status === 200) {  
        console.log('getGroupChains', res.data);        
        dispatch(setGroupChains(res.data));                                       
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })   
    .finally(() => dispatch(setLoading(false)) );     
};

// --------Реєстр логістичних ланцюжків групи вагонів по ID групи
export const getGroupChainsByGroup = async (idGroup) => {
  dispatch(setLoading(true));
  await axios.get(`api-vantazh/Slcs/LLG/${idGroup}`)
  .then(res => {
    if(res.status === 200) {  
      console.log('getGroupChainsByGroup', res.data);        
      dispatch(setGroupChains(res.data));                                       
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })   
  .finally(() => dispatch(setLoading(false)) );     
};

// --------видалення логістичного ланцюжка групи вагонів
export const deleteSLC = (id, name) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.groupChains.titleDelete'),
    content: `${i18n.t('service.groupChains.answerDelete')} ${name}?`,
    onSubmit: () => submitDelete(id),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDelete = async (id) => {  
  await axios.delete(`/api-vantazh/Slcs/${id}`) 
  .then(res => {
    if(res.status === 200) {
      getGroupChains();
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.groupChains.deleted'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });    
};

// --------видалення логістичного ланцюжка групи вагонів для групи вагонів
export const deleteSLCByIdGroup = (id, name, idGroup) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.groupChains.titleDelete'),
    content: `${i18n.t('service.groupChains.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteByIdGroup(id, idGroup),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteByIdGroup = async (id, idGroup) => { 
  await axios.delete(`/api-vantazh/Slcs/${id}`) 
    .then(res => {
      if(res.status === 200) {
        getGroupChainsByGroup(idGroup);
        showConfirmationModal({ isOpen: false });
        toast.success(i18n.t('service.groupChains.deleted'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });        
};


// --------класифікатор станів
export const getDicStates = async () => {  
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=status&all=true`)
  .then(res => {
    if(res.status === 200) {
      const newDataLabel = [];        
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.value, 'label': item.title})                  
      });         
      dispatch(setDicStates(newDataLabel));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });    
};

// ---------------------------------
export const openGroupChanViewModal = async (idSlc) => {
  getChainMainInfo(idSlc);
  dispatch(setOpenChainViewModal(true));
};

export const closeGroupChainViewModal = async () => { 
  dispatch(setOpenChainViewModal(false));
};

// --------Запит тітульної інформації про ланцюжок
export const getNewChainMain = async () => {
  dispatch(setChainMain(null)); 
  // dispatch(setDicLLG([])); 
};

export const getChainMain = async (idSlc) => {   
  // https://dev.vantazhplus.co.ua/api-vantazh/Slcs/1?withChildren=false
  await axios.get(`api-vantazh/Slcs/${idSlc}?withChildren=false`)
  .then(res => {
    if(res.status === 200) {  
      console.log("getChainMain", res.data);     
      dispatch(setChainMain(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

export const getChainMainInfo = async (idSlc) => {   
 await axios.get(`api-vantazh/Slcs/${idSlc}?withChildren=false`)
  .then(res => {
    if(res.status === 200) {  
      console.log("getChainMainInfo", res.data);     
      dispatch(setChainMainInfo(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

export const getChainMainAll = async (idSlc) => {   
  // https://dev.vantazhplus.co.ua/api-vantazh/Slcs/1?withChildren=true
  await axios.get(`api-vantazh/Slcs/${idSlc}`)
  .then(res => {
    if(res.status === 200) {  
      console.log("getChainMainAll", res.data);     
      dispatch(setChainMain(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------НДІ Групи вагонів
export const getDicCargoGroups = async () => {      
  await axios.get(`api-vantazh/contracts/Group`)
   .then(res => {
     if(res.status === 200) { 
      console.log("getDicCargoGroups", res.data); 
      const newData = [];        
      res.data.forEach(item => {  
        /* if (item.prLLG !== 1) { */        
          const newItem = {'value': item.idCargoGroup, 
                          'title': `${item.tidGroup.trim()} (${item.tidContract.trim()})`,
                          'idContract': item.idContract, 
                          'idPart': item.idPart,
                          'nameStationFrom': item.nameStationFrom,
                          'codeStationFrom': item.codeStationFrom,
                          'nameAdmTo': item.nameAdmTo,
                          'admStationTo': item.admStationTo,
                          'nameStationTo': item.nameStationTo,
                          'codeStationTo': item.codeStationTo,
                          'nameCargoETSNV': item.nameCargoETSNV,
                          'codeCargoETSNV': item.codeCargoETSNV,
                          'countCar': item.countCar,
                          'dateBegCargo': item.dateBegCargo,
                          'dateEndCargo': item.dateEndCargo,
                          'prLLG': item.prLLG
                          };
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          } 
        // }                
      }); 
      // console.log("getDicCargoGroups", newData);         
      dispatch(setDicCargoGroups(newData));                                     
     }
     else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {    
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });   
};

export const getDicCargoGroupsById = async (idCargoGroup) => {
  await axios.get(`api-vantazh/contracts/Group?idGroup=${idCargoGroup}`)
   .then(res => {
     if(res.status === 200) { 
      console.log("getDicCargoGroupsById", res.data); 
      const item = {...res.data};
      const newData = [];        
      newData.push(            
        { 'value': item.idCargoGroup, 
          'title': `${item.tidGroup.trim()} (${item.tidContract.trim()})`,
          'idContract': item.idContract, 
          'idPart': item.idPart,
          'nameStationFrom': item.nameStationFrom,
          'codeStationFrom': item.codeStationFrom,
          'nameAdmTo': item.nameAdmTo,
          'admStationTo': item.admStationTo,
          'nameStationTo': item.nameStationTo,
          'codeStationTo': item.codeStationTo,
          'nameCargoETSNV': item.nameCargoETSNV,
          'codeCargoETSNV': item.codeCargoETSNV,
          'countCar': item.countCar,
          'dateBegCargo': item.dateBegCargo,
          'dateEndCargo': item.dateEndCargo,
          'prLLG': item.prLLG
        }); 
      // console.log("getDicCargoGroupsById", newData);        
      dispatch(setDicCargoGroups(newData));                                    
     }
     else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {    
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------НДІ НЛЛ
export const getDicNLL = async () => {  
  await axios.get(`api-vantazh/Rlcs?withFullData=true`)
  .then(res => {
    if(res.status === 200) {
      // console.log("getDicNLL", res.data);      
      const newDataLabel = [];        
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.idRlc, 'title': item.tidRlc,
        'codeStationFrom': item.sendCodeStation,
        'admStationTo': item.recCodeAdm,
        'codeStationTo': item.recCodeStation,
        'codeCargoETSNV': item.codeCargoEtsnv,
      })                  
      }); 
      // console.log("getDicNLL", newDataLabel);       
      dispatch(setDicNLL(newDataLabel));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });    
};

// --------НДІ ЛЛГ
export const getDicLLG = async (idPart) => {
  console.log('idPart', idPart); 
  if (idPart === null || idPart === undefined) 
  {
    dispatch(setDicLLG([])); 
    return; 
  }
  await axios.get(`api-vantazh/Slcs/LLG?idPart=${idPart}`)
  .then(res => {
    if(res.status === 200) {  
      console.log('getDicLLG', res.data);            
      const newDataLabel = [];        
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.idSlc, 'title': `${item.tidSlc} (${item.tidGroup})`,
        'idGroup': item.idGroup,
        'idRlc': item.idRlc,
        'codeCargoETSNV': item.codeCargoEtsnv,
      })                  
      });       
      dispatch(setDicLLG(newDataLabel));                                       
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })        
};

// --------збереження  ЛЛГ 
export const saveChainMain = async (data) => {
  await axios.put(`api-vantazh/Slcs`, data)
    .then(res => {
      if(res.status === 200) {
        // console.log("saveChainMain", res.data);
        dispatch(setChainMain(res.data));   
        toast.success(i18n.t('service.groupChains.saved'));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження  ЛЛГ (NEW) ByRLC
export const saveNewChainMainByRLC = async (idRlc, idGroup, nameSlc) => {
  await axios.get(`api-vantazh/Slcs/NewSlcGroupFromRlc/${idRlc}/${idGroup}?nameSlc=${encodeURIComponent(nameSlc)}`)
    .then(res => {
      if(res.status === 200) {
        // console.log("saveNewChainMainByRLC", res.data);
        dispatch(setChainMain(res.data));   
        toast.success(i18n.t('service.groupChains.saved'));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження  ЛЛГ (NEW) ByLLG
export const saveNewChainMainByLLG = async (idSlc, idGroup, nameSlc) => {
  await axios.get(`api-vantazh/Slcs/NewSlcGroupFromLlg/${idSlc}/${idGroup}?nameSlc=${encodeURIComponent(nameSlc)}`)
    .then(res => {
      if(res.status === 200) {
        console.log("saveNewChainMainByLLG", res.data);
        dispatch(setChainMain(res.data));   
        toast.success(i18n.t('service.groupChains.saved'));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------Реєстр дільниць перевезення 
export const getSections = async (idSlc) => {
  dispatch(setLoading(true));  
  await axios.get(`api-vantazh/Slcs/${idSlc}/Carriers`)
  .then(res => {
    if(res.status === 200) { 
      console.log("getSections", res.data);      
      dispatch(setSections(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })  
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })
  .finally(() => dispatch(setLoading(false)) );
}


// --------Дільниця перевезення 
export const getSection = async (IdSection) => {
  await axios.get(`api-vantazh/SlcCarriers/${IdSection}`)
  .then(res => {
    if(res.status === 200) { 
      console.log("getSection", res.data);
      dispatch(setSection(res.data));  
  }
  else {
    throw new Error(res.statusText || res.status);          
  }  
  }
  )
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  }); 
};


export const getNewSection = async (idSlc, section = null) => {
  getChainMain(idSlc);
  dispatch(setSection({section}));  
};

// --------видалення Дільниці перевезення 
export const deleteSection = (idSection, name, idSlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.groupChains.titleDelete'),
    content: `${i18n.t('service.groupChains.sections.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteSection(idSection, idSlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteSection = async (idSection, idSlc) => { 
  await axios.delete(`api-vantazh/SlcCarriers/${idSection}`)
  .then(res => {
    if(res.status === 200) {
      getSections(idSlc);      
      showConfirmationModal({ isOpen: false });    
      getChainMain(idSlc);   
      toast.success(i18n.t('service.groupChains.sections.deleted'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------модальное окно для создания/редактирования Дільниці перевезення
export const openSectionModal = async (idSection) => {
  
  getSection(idSection);
  dispatch(setSectionModal(true));
}; 

export const closeSectionModal = async () => { 
  dispatch(setSectionModal(false));
};

export const openNewSectionModal = async (idSlc) => {
  getNewSection(idSlc);
  dispatch(setSectionModal(true));
};

// --------збереження Дільниці перевезення
export const saveSection = async (data) => {
  await axios.put(`api-vantazh/SlcCarriers`, data)
    .then(res => {
      if(res.status === 200) {
        getSections(data.idSlc);
        dispatch(setSection(res.data));  
        getChainMain(data.idSlc);
        // toast.success(`Дільницю ${data.tidSection} успішно збережено.`);     
        toast.success(i18n.t('service.groupChains.sections.saved'));     
        dispatch(setSectionModal(false));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження НОВОЇ Дільниці перевезення
export const saveNewSection = async (data) => {  
    await axios.post(`api-vantazh/SlcCarriers`, data)
    .then(res => {
      if(res.status === 200) {
        getSections(res.data.idSlc);
        dispatch(setSection(res.data));  
        getChainMain(data.idSlc); 
        toast.success(i18n.t('service.groupChains.sections.created'));     
        dispatch(setSectionModal(false));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ адміністрація призначення
export const getDicAdm = async () => {  
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_D_ADM&fieldOutOne=N_ADMR_${localStorage.getItem('i18nextLng')}&fieldOutTwo=K_ADMI&fieldOutThree=k_strn&all=true`)
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_ADM&titleOut=N_ADMR&valueOut=K_ADMI&all=true')
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicAdm(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });         
};
// --------НДІ Станції
export const getDicStationsAll = async () => {
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info_${localStorage.getItem('i18nextLng')}&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });  
        dispatch(setDicStationsAll(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

// --------НДІ перевізникі
export const getDicCarriers = async () => {  
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Ekbs_Carriers&fieldOutOne=id_vsp&fieldOutTwo=display_info_${localStorage.getItem('i18nextLng')}&fieldOutThree=carrier_adm&all=true`)  
    .then(res => {
      if(res.status === 200) {
        console.log('getDicCarriers', res.data);
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field1, 'title': item.field2.trim(), 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicCarriers(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });         
};

// --------НДІ платникі
export const getDicPayers = async ( /* idOwner, idCustomer */  idGroup ) => {
  // console.log('getDicPayers idGroup=', idGroup); 
  const data =`"with participants as (` +
                    `select id_owner, id_customer` +
                    ` from IC.Cargo_Group gr, IC.Cargo_Main c` +
                    ` where c.id_contract = gr.id_contract` +
                    ` and gr.id_group = ${idGroup})` +

                    ` select distinct p.id_vsp_ekbs as value, p.name_short as title, p.code_country as country` +
                    ` from views.v_eekk_platnuk p` +
                    ` inner join (` +
                    `select id_saller from pidpr.Ekbs_Pidpr_Agreements pa where pa.agree_type = 7` +
                    ` union select participants.id_owner from participants` +
                    ` union select participants.id_customer from participants) pa` +
                    ` on pa.id_saller = p.id_vsp_ekbs"`;

/*
  const data = "\"  select distinct p.id_vsp_ekbs as value, p.name_code as title, p.code_country as country   " +
                 "  from views.eekk_platnuk p, pidpr.Ekbs_Pidpr_Agreements pa   " +
                 "  where pa.agree_type= 7 and pa.id_saller = p.id_vsp_ekbs     \""; 
*/
                 /* Надо, чтобы выборка была для idOwner и idCustomer из контракта.
                    Т.е. в where добавить pa.id_customer = idOwner or pa.id_customer = idCustomer

                    Кроме того, в результирующую выборку через INION ALL
                    добавить инфу и о самих владельцах контракта. Причем только в том случае, если они 
                    есть во вьюшке views.eekk_platnuk (т.е. м.б. плательщиками)
                  */
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicPayers', res.data);        
        dispatch(setDicPayers(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });  
};

export const getDictionaryPayers = async ( idGroup ) => {
  const data =`"with participants as (` +
                    `select id_owner, id_customer` +
                    ` from IC.Cargo_Group gr, IC.Cargo_Main c` +
                    ` where c.id_contract = gr.id_contract` +
                    ` and gr.id_group = ${idGroup})` +

                    ` select distinct p.id_vsp_ekbs as value, p.name_code as title, p.code_7 as code, p.code_country as country` +
                    ` from views.v_eekk_platnuk p` +
                    ` inner join (` +
                    `select id_saller from pidpr.Ekbs_Pidpr_Agreements pa where pa.agree_type = 7` +
                    ` union select participants.id_owner from participants` +
                    ` union select participants.id_customer from participants) pa` +
                    ` on pa.id_saller = p.id_vsp_ekbs"`

  return axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
    .then(res => {
      if(res.status === 200) {      
        return res.data
      }          
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true})
    })
}

// --------Події
export const getChainActions = async (idSlc) => {
  dispatch(setLoading(true));  
  
  await axios.get(`api-vantazh/Slcs/${idSlc}/Actions?withChildren=true`)
  .then(res => {
    if(res.status === 200) {   
      // console.log('getChainActions', res.data);  
      res.data = res.data.map(action => {        
        action.services.map(service => {
          if (service.executors)
          {            
            const namesExecutors = getNamesExecutors(service.executors);            
            service.executors = namesExecutors;
          }
          else service.executors = [];
          return service;
        });
        return action;
      });
      console.log('getChainActions', res.data);   
      dispatch(setChainActions(res.data));
    }
    else {   
      throw new Error(res.statusText || res.status);          
    }      
})  
.catch((error) => {      
  toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
})
.finally(() => dispatch(setLoading(false)) );
};
export const getNamesExecutors = (executors) => {
  const names = [];
  executors.forEach((executor) => {
    names.push(`${ executor.nameSaller}`); 
    if (executor.executors)   
      getNamesAllExecutors(names, executor.executors);
  });
  return names;
};

const getNamesAllExecutors = (names, executors) => {  
  executors.forEach((executor) => { 
    names.push(`${ executor.nameSaller}`); 
    if (executor.executors)   
      getNamesAllExecutors(names, executor.executors);
  });
  return names;
};

// --------persistActions
export const persistActions = async (actions) => {
  dispatch(setChainActions(actions)); 
  dispatch(setMoved(true));    
}

// --------модальное окно для создания/редактирования Події
export const openActionModal = async (idAction) => {
  getChainAction(idAction);
  dispatch(setActionModal(true));
}; 

export const closeActionModal = async () => { 
  dispatch(setActionModal(false));
};

export const getNewChainAction = async (idSlc, action = null) => {
  getChainMain(idSlc);
  // getChainMainAll(idSlc);
  getChainActions(idSlc);
  getDicAdmAction(idSlc);
  getDicStationsAction(idSlc); 
  dispatch(setChainAction({action}));  
};

export const openNewActionModal = async (idSlc) => {
  getNewChainAction(idSlc);  
  dispatch(setActionModal(true));
};

// --------Подія
export const getChainAction = async (idAction) => {  
  
  await axios.get(`api-vantazh/SlcActions/${idAction}`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("getChainAction", res.data);   
      getDicAdmAction(res.data.idSlc, res.data.idAction);  
      getDicStationsAction(res.data.idSlc, res.data.idAction);   
      dispatch(setChainAction(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження Події
export const saveChainAction = async (data) => {  
  await axios.put(`api-vantazh/SlcActions`, data)
    .then(res => {
      if(res.status === 200) {
        // console.log('saveChainAction', res.data);
        getChainActions(res.data.idSlc);
        dispatch(setChainAction(res.data));   
        toast.success(i18n.t('service.groupChains.chainActions.saved')); 
        dispatch(setActionModal(false));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};
// --------збереження НОВОЇ Події
export const saveNewChainAction = async (data) => {
  await axios.post(`api-vantazh/SlcActions`, data)
    .then(res => {
      if(res.status === 200) {
        getChainActions(res.data.idSlc);
        dispatch(setChainAction(res.data));   
        toast.success(i18n.t('service.groupChains.chainActions.created'));
        dispatch(setActionModal(false));     
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження порядка
export const saveOrder = async (idSlc, data) => {
  await axios.patch(`api-vantazh/Slcs/Reorder/${idSlc}`, data)
    .then(res => {
      if(res.status === 200) {
        // getChainActions(data.idSlc);
        dispatch(setChainActions(res.data));   
        dispatch(setMoved(false));
        toast.success(i18n.t('service.groupChains.savedOrder'));     
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------модальное окно для создания/редактирования Послуги
export const openServiceModal = async (idService) => {
  getActionService(idService);
  dispatch(setServiceModal(true));
}; 

export const closeServiceModal = async () => { 
  dispatch(setServiceModal(false));
};

export const getNewService = async (action, service = null) => {  
  dispatch(setChainAction(action));
  dispatch(setActionService({service}));  
};

export const openNewServiceModal = async (action) => {
  // getChainMain(action.idSlc);
  getNewService(action);
  dispatch(setServiceModal(true));
};

// --------Видалення Послуги
export const deleteService = (idService, name, idSlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.groupChains.titleDelete'),
    content: `${i18n.t('service.groupChains.chainActions.answerDeleteService')} ${name}?`,
    onSubmit: () => submitDeleteService(idService, idSlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteService = async (idService, idSlc) => { 
  await axios.delete(`api-vantazh/SlcServices/${idService}`)
  .then(res => {
    if(res.status === 200) {      
      getChainActions(idSlc);      
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.groupChains.chainActions.deletedService'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------Послуга
export const getActionService = async (idService) => {  
  await axios.get(`api-vantazh/SlcServices/${idService}`)
  .then(res => {
    if(res.status === 200) {  
      console.log("getActionService", res.data);     
      dispatch(setActionService(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
    })   
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження Послуги
export const saveActionService = async (data) => {
  await axios.put(`api-vantazh/SlcServices`, data)
    .then(res => {
      if(res.status === 200) {
        getChainActions(res.data.idSlc);
        dispatch(setActionService(res.data));   
        toast.success(i18n.t('service.groupChains.chainActions.savedService')); 
        dispatch(setServiceModal(false));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження НОВОЇ Послуги
export const saveNewActionService = async (data) => {  
  await axios.post(`api-vantazh/SlcServices`, data)
  .then(res => {
    if(res.status === 200) {
      getChainActions(res.data.idSlc);      
      dispatch(setActionService(res.data));   
      toast.success(i18n.t('service.groupChains.chainActions.createdService')); 
      dispatch(setServiceModal(false));    
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });
};


// --------НДІ лінковочна події-послуги по codeAction 
export const getDicLinkServicesByIdAction = async (codeAction, countCar) => { 
  // console.log('getDicLinkServicesByIdAction id = ', codeAction); 
  const data =
  `"  SELECT code_service as value, name_service_${localStorage.getItem('i18nextLng')} as title, ` + 
  `   code_obj_sum as codeObjSum, name_obj_sum_${localStorage.getItem('i18nextLng')} as nameObjSum, code_obj_duration as codeObjDuration, name_obj_duration_${localStorage.getItem('i18nextLng')} as nameObjDuration, is_key as isKey ` +
  ` (case code_obj_sum            ` +
  `    WHEN 1 THEN ${countCar}    ` +
  `      WHEN 2 THEN 1            ` +
  `    WHEN 3 THEN 1              ` +
  `    WHEN 4 THEN ${countCar}    ` +
  `    WHEN 5 THEN 1              ` +
  `    WHEN 7 THEN 1              ` +
  `      ELSE 0                   ` +
  `  END ) as countObjSum,        ` +
  `  (case code_obj_duration      ` +
  `    WHEN 1 THEN ${countCar}    ` +
  `      WHEN 2 THEN 1            ` +
  `    WHEN 3 THEN 1              ` +
  `    WHEN 4 THEN ${countCar}    ` +
  `    WHEN 5 THEN 1              ` +
  `    WHEN 7 THEN 1              ` +
  `      ELSE 0                   ` +
  `  END ) as countObjDuration    ` +
  ` FROM views.V_Link_Actions_Services las  ` +
  ` WHERE las.code_action=${codeAction}     ` +
  ` order by order_service "`;
 
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        // console.log('getDicLinkServicesByIdAction', res.data); 
        dispatch(setDicLinkServices(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

// --------НДІ лінковочна події-послуги по codeAction 
// ----------------!!! То, исользуется сейчас
export const getDicLinkServicesByCodeAction = async (codeAction, idSlc, idGroup) => { 
  console.log('getDicLinkServicesByCodeAction idSlc=', idSlc);
  await axios.get(`api-vantazh/SlcServices/${idSlc}/${idGroup}/${codeAction}/Nsi`)
    .then(res => {
      if(res.status === 200) {
        console.log('getDicLinkServicesByCodeAction', res.data);
        // const filterData = res.data.filter(x => Number(x.codeAction) === Number(codeAction));
        // console.log('getDicLinkServicesByCodeAction newData', filterData);   
        const newData = [];        
          res.data.forEach(item => {            
              const newItem = 
              {'value': item.codeService, 'title': item.tidService.trim(),   
                'codeObjSum': item.codeObjSum,
                'nameObjSum': item.nameObjSum, 
                'codeObjDuration': item.codeObjDuration,
                'nameObjDuration': item.nameObjDuration, 
                'isKey': item.isKey,
                'countObjSum' : item.countObjSum,
                'countObjDuration' : item.countObjDuration,
                // 'priceNorm' : item.priceNorm,
                // 'durationNorm' : item.durationNorm
              };
              if (!newData.includes(newItem)) {
                newData.push(newItem)
              }                 
            });                       
        dispatch(setDicLinkServices(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
}

// --------НДІ лінковочна події-послуги всі
/*
export const getDicLinkServicesAll = async () => { 
  
  const data =  "\" SELECT code_action as codeAction, order_service as orderService, " +
                " 0 as idService, 0 as idAction, 0 as idRlc," +
                " code_service as codeService, name_service as nameService, " +
                " code_obj_sum as codeObjSum, name_obj_sum as nameObjSum, " +
                " code_obj_duration as codeObjDuration, name_obj_duration as nameObjDuration, is_key as isKey, " +
                " '' as description, 0 as price,  '' as nameCurrency, 980 as codeCurrency, 0 as duration " +
                " FROM views.V_Link_Actions_Services " +
                " order by code_action, order_service \"";
  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        console.log('getDicLinkServicesAll', res.data);         
        dispatch(setDicLinkServicesAll(res.data));
      }      
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};
*/
export const getDicLinkServicesAll = async (idSlc, idGroup) => {   
  await axios.get(`api-vantazh/SlcServices/${idSlc}/${idGroup}/Nsi`)
    .then(res => {
      if(res.status === 200) {  
        // console.log('getDicLinkServicesAll', res.data);               
        const newData = [];        
          res.data.forEach(item => {            
              const newItem = {...item};
              newItem.idSlc = idSlc;
              newItem.idGroupService = idGroup;
              newItem.idAction = 0;
              newItem.idService = 0;
              newItem.nameService = '';
              newItem.codeCurrency = '980';
              newItem.priceNorm = 0;
              newItem.price = 0;
              newItem.durationNorm = 0;
              newItem.duration = 0;
              newItem.status = 1;              
              if (!newData.includes(newItem)) {
                newData.push(newItem)
              }                 
            });   
        console.log('getDicLinkServicesAll newData', newData);                             
        dispatch(setDicLinkServicesAll(newData));
      } 
      else {
        throw new Error(res.statusText || res.status);          
      }       
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ Код об’єкта застосування
export const getDicUnit = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Services_Unit&titleOut=name_short_${localStorage.getItem('i18nextLng')}&valueOut=code_unit&all=true`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicUnit(res.data));
      }
      else {                  
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ валюта
export const getDicCurrency = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_VALUT&titleOut=K_VALC&valueOut=K_VALN&all=true`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicCurrency(res.data));
      }
      else {                 
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ Події
export const getDicActions = async () => {
  
  const data = `" SELECT l.code_action AS value, concat(l.name_short_${localStorage.getItem('i18nextLng')},' (',l.code_action,')') AS title ` +
              ` FROM nsi_ekbs.Dic_Actions_Form l ` +
              ` WHERE l.code_action in ` +
              ` (SELECT  distinct code_action ` +
              ` FROM views.V_Link_Actions_Services v WHERE l.code_action=v.code_action) "`;            
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Actions_Form&titleOut=name_full&valueOut=code_action&all=true')  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )  
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicActions', res.data);        
        dispatch(setDicActions(res.data));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }     
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------Видалення Події
export const deleteAction = (idAction, name, idSlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.groupChains.titleDelete'),
    content: `${i18n.t('service.groupChains.chainActions.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteAction(idAction, idSlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteAction = async (idAction, idSlc) => {  
  await axios.delete(`api-vantazh/SlcActions/${idAction}`)
  .then(res => {
    if(res.status === 200) {      
      getChainActions(idSlc);      
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.groupChains.chainActions.deleted'));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }      
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });
 };

// --------модальное окно Визначення виконавців послуг
export const openServiceExecutorsModal = async (idService) => {
  // getExecutors(idService);
  getServiceExecutors(idService);
  getDicAgreements(idService);
  dispatch(setOpenServiceExecutorsModal(true));
};

export const closeServiceExecutorsModal = async () => { 
  dispatch(setDicAgreements([]));
  dispatch(setOpenServiceExecutorsModal(false));
};

// --------Перелік договорів по послузі
export const getDicAgreements = async (idService) => {  
  dispatch(setLoading(true));
  await axios.get(`api-vantazh/SlcServices/${idService}/Agreements`)
  .then(res => {
    if(res.status === 200) {
      console.log('getDicAgreements', res.data); 
      
            
      if (res.data && res.data.length > 0)
      {        
        const hierarchycalAgreements = getHierarchycalAgreements( res.data);
        // console.log('hierarchycalAgreements', hierarchycalAgreements);
        // дополним каждую запись атритутом isSelected
        let newItems = [...hierarchycalAgreements];   
        newItems = newItems.map(item => {
            const newItem = {...item};
            newItem.isSelected = item.isSelected || false;
            return newItem;
        });
        console.log('hierarchycalAgreements', newItems);
        dispatch(setDicAgreements(newItems));
      }  
      else     
        dispatch(setDicAgreements([]));       
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }      
  })
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })
  .finally(() => dispatch(setLoading(false)) );   
};

const getHierarchycalAgreements = (agreements) => {
  const result = []

  agreements.forEach((agreement) => {
    const hierarchycalAgreements = getAllAgreements([`${ agreement.idAgreement}`], agreement);
    result.push(...hierarchycalAgreements);
  });
  return result;
};

const getAllAgreements = (hierarchy, agreement) => {
  const results = []
  results.push({...agreement, hierarchy})

  if (agreement.executors) {
    agreement.executors.forEach((child ) => {
      const childHierarchy = [...hierarchy]
      childHierarchy.push(`${ child.idAgreement}`)
      const agreements = getAllAgreements(childHierarchy, child)
      results.push(...agreements);
    })
  };
  return results;
};

// --------Перелік виконавців по послузі
export const getExecutors = async (idService) => {
  dispatch(setLoading(true));
  await axios.get(`api-vantazh/SlcServices/${idService}/Customers`)
  .then(res => {
    if(res.status === 200) {  
      console.log('getExecutors', res.data);        
      dispatch(setExecutors(res.data));                                       
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }      
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })   
  .finally(() => dispatch(setLoading(false)) );     
};

// --------Послуга + Перелік виконавців по послузі
export const getServiceExecutors = async (idService) => {
  dispatch(setLoading(true));
  //   /api-vantazh/SlcServices/{idService}?withChildren=true
  await axios.get(`/api-vantazh/SlcServices/${idService}?withChildren=true`)
  .then(res => {
    if(res.status === 200) {  
      console.log('getServiceExecutors', res.data);        
      dispatch(setActionService(res.data));                                       
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }      
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })   
  .finally(() => dispatch(setLoading(false)) );     
};

// --------Збереження виконавців по послузі
export const saveServiceExecutors = async (data, idSlc) => { 
  dispatch(setLoading(true));
  await axios.post(`api-vantazh/SlcExecutors?withDeleting=true`, data)
  .then(res => {
    if(res.status === 200) {
      getDicAgreements(data.idService);
      getChainActions(idSlc);           
      toast.success(i18n.t('service.groupChains.chainActions.modal.saveExecutors'));
      dispatch(setOpenServiceExecutorsModal(false));     
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }      
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })  
  .finally(() => dispatch(setLoading(false)) );
};

// --------Видалення виконавців по послузі
export const deleteServiceExecutors = (idService, name, idSlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.groupChains.titleDelete'),
    content: `${i18n.t('service.groupChains.chainActions.modal.answerDeleteExecutors')} "${name}"?`,
    onSubmit: () => submitDeleteExecutors(idService, idSlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteExecutors = async (idService, idSlc) => { 
  dispatch(setLoading(true)); 
  // await axios.delete(`api-vantazh/SlcExecutors/${idService}`) 
  await axios.delete(`api-vantazh/Executors/ByService/${idService}`) 
  .then(res => {
    if(res.status === 200) {      
      getDicAgreements(idService);
      getChainActions(idSlc);     
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.groupChains.chainActions.modal.deletedExecutors'));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }      
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })
  .finally(() => dispatch(setLoading(false)) );
 };

 // --------методи формування переліку адміністрацій і станцій для вибору даних 
// --------при введенні/коригуванні станцій здійснення подій НЛЛ/ЛЛГ (згідно заув. 35)

// --------НДІ адміністрація призначення
export const getDicAdmAction = async (idSlc, idAction = '') => {  
  await axios.get(`api-vantazh/Slcs/${idSlc}/Adms/${idAction}`)  
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicAdmAction', res.data);
        dispatch(setDicAdm(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });         
};
// --------НДІ Станції
export const getDicStationsAction = async (idSlc, idAction = '') => {
  await axios.get(`api-vantazh/Slcs/${idSlc}/Stations/${idAction}`) 
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicStationsAction', res.data);        
        dispatch(setDicStationsAll(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

 
 








  
