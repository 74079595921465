
import { Link } from 'react-router-dom'

// @mui
import { useTheme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// locales
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------
export default function StartCabinet() {    
    const { translate } = useLocales();
    const theme = useTheme();
    return (
        <>
        <Tooltip title={translate('startCabinet.home')} followCursor>
            <IconButtonAnimate  component={Link} 
                                to={PATH_DASHBOARD.general.cabinet}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    // bgcolor: 'action.selected'
                                  }}>
                <Iconify    icon={'eva:home-fill'} 
                            // color="#DF3E30" 
                            color = {theme.palette.primary.main}                            
                            width={24} 
                            height={24}/>
                </IconButtonAnimate> 
        </Tooltip>        
        </>
    );
}