import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
import { customPointDateFormat } from '../../utils/formatTime';
//
import { dispatch } from '../store';

const DATE_START = new Date().setMonth(new Date().getMonth() - 22);
const DATE_FINISH = new Date().setMonth(new Date().getMonth() + 6);

const initialState = {
    isLoading: false,
    isOpenModal: false,
    exeServices: [],
    exeService: null,
    dicExeObjects: [],
    dicStationsAll: [],
    period: {
      dateBeg: DATE_START,
      dateEnd: DATE_FINISH, 
    },
    exeActions: [],
    exeAction: null
    
};

const slice = createSlice({
    name: 'exeServices',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setExeModal(state, action) {
          state.isOpenModal = action.payload;
        },
        setExeServices(state, action) {
            state.exeServices = action.payload;
        },
        setExeService(state, action) {
          state.exeService = action.payload;
        },
        setDicExeObjects(state, action) {
          state.dicExeObjects = action.payload;
        },
        setDicStationsAll(state, action) {
            state.dicStationsAll = action.payload;
        },
        setPeriod(state, action) {
          state.period = action.payload;
        },
        setExeActions(state, action) {
          state.exeActions = action.payload;
        },
        setExeAction(state, action) {
          state.exeAction = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    setExeModal,
    setExeServices,
    setExeService,
    setDicExeObjects,
    setDicStationsAll,
    setPeriod,
    setExeActions, setExeAction
} = slice.actions;
/*
// --------НДІ Станції
export const getDicStationsAll = async () => {
    await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info_${localStorage.getItem('i18nextLng')}&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
      .then(res => {
        if(res.status === 200) {
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            } 
            else {      
              throw new Error(res.statusText || res.status);          
            }                  
          });  
          dispatch(setDicStationsAll(newData));
        }
      })
      .catch((error) => {    
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });        
  };
*/
// --------класифікатор объектов для выполнения услуги
export const getExeObjects = async () => {  
  const dic = [];
  dic.push({'value': 0, 'label': i18n.t('service.exeServices.columns.route')});
  dic.push({'value': 1, 'label': i18n.t('service.exeServices.columns.carGroup')});
  dispatch(setDicExeObjects(dic));  
};

// --------Оновлення періода запиту даних
// 
export const persistPeriod = async (newPeriod) => {  
  dispatch(setPeriod(newPeriod));             
};

// --------Перелік послуг для груп вагорів у контракті
// 
  export const getExeServicesByPeriod = async ( idVsp, 
                                                currentDateBeg=DATE_START, 
                                                currentDateEnd=DATE_FINISH) => {

    const dateBeg = customPointDateFormat(currentDateBeg);
    // console.log('dateBeg', dateBeg );
    const dateEnd = customPointDateFormat(currentDateEnd);
    // console.log('dateEnd', dateEnd);
    dispatch(setLoading(true));
    const data =`"SELECT  DISTINCT                                          ` +
                ` services.id_service as idService                          ` +
                `  , concatData.idExecutor as idExecutor                    ` +
                `  , services.code_service as  codeService                  ` +                                     
                `  , services.is_key  as isKey                              ` +
                `  , IF(services.name_service is null, dic_services.name_short_${localStorage.getItem('i18nextLng')}, services.name_service) as tidService         ` +
                `  , stations.display_info_${localStorage.getItem('i18nextLng')} as station                       ` +                              
                `  , IF(services.id_group IS NULL, services.date_plan_beg, IFNULL(executors.date_plan_beg, services.date_plan_beg)) as dateBegin    ` +
                `  , IF(services.id_group IS NULL, services.date_plan_end, IFNULL(executors.date_plan_end, services.date_plan_end)) as dateEnd      ` +
                `  , services.service_duration as duration                  ` +

                `  , IF(services.id_group IS NULL, 0, 1) as nameObject      ` +                                 
                `  , IF(services.id_group IS NULL, main.id_route, services.id_group) as idObject                          ` + 
                `  , IF(services.id_group IS NULL, routes.count_vag, gr.count_vag) as countObjects                        ` +
                `  , IF(services.id_group IS NULL, services.cost_actual, IFNULL(executors.cost_actual, services.cost_actual)) as costActual         ` +
                `  , dic_currencys.k_valc as currencyName                   ` +
                `  , dic_services.code_obj_sum   as codeObjSum              ` +                                           
                `  , dic_units.name_full_${localStorage.getItem('i18nextLng')} as unit                           ` +

                `  , agreements.number_agreement as numberAgreement         ` +                                                   
                `  , agreements.agree_date  as dateAgreement                ` +                        
                `  , agreements.id_customer as idCustomer                   ` +                                                             
                `  , dic_pidpr_customer.name_short as nameCustomer          ` +
                `  /* , sallers.listExecutorsPlan   */                      ` +
                `  , (SELECT  count(*) FROM IC.Slc_Services_Executors e     ` +                                          
                `    WHERE e.id_service=executors.id_service and e.parent_id_executor=executors.id_executor  ` +       
                `    ) as countChildren                                     ` +
                `  , childExecutor.name_short as childName                  ` +
                `  , childExecutor.number_agreement as childAgreement       ` +
                `  , childExecutor.agree_date as childAgreementDate         ` +

                `  FROM IC.Slc_Services services                            ` +
                `  INNER JOIN IC.Slc_Main main on services.id_slc = main.id_slc and main.status = 3 and main.type_slc = 1 and main.id_route is not null   ` +
                `  INNER JOIN IC.Cargo_Route routes on main.id_route = routes.id_route                                                                    ` +
                `  INNER JOIN IC.Slc_Services_Executors executors on services.id_service = executors.id_service                                           ` +
                `  INNER JOIN pidpr.Ekbs_Pidpr_Agreements agreements on executors.id_agreement = agreements.id_agree and agreements.id_saller=${idVsp}    ` +
                `  INNER JOIN views.V_Ekbs_Pidpr_All dic_pidpr_customer ON agreements.id_customer = dic_pidpr_customer.id_vsp                             ` +
                `  INNER JOIN IC.Slc_Actions actions ON services.id_action = actions.id_action                                                            ` +
                `  LEFT JOIN views.V_Stations stations ON actions.action_adm = stations.code_adm and actions.action_station = stations.code_station       ` +
                `  LEFT JOIN nsi_ekbs.Dic_Pidpr_Services dic_services on dic_services.code_service = services.code_service                                ` +
                `  LEFT JOIN IC.Cargo_Group gr on services.id_group = gr.id_group                                                                         ` +
                `  LEFT JOIN nsi.E_D_VALUT dic_currencys ON dic_currencys.k_valn = services.currency_code                                                 ` +
                `  LEFT JOIN nsi_ekbs.Dic_Services_Unit dic_units on dic_units.code_unit = dic_services.code_obj_sum                                      ` +
                `  LEFT JOIN                                                                                                      ` +      
                `      (SELECT e.parent_id_executor, dic_pidpr.name_short, agreements.number_agreement, agreements.agree_date     ` +
                `        FROM IC.Slc_Services_Executors e                                                                         ` +
                `        INNER JOIN pidpr.Ekbs_Pidpr_Agreements agreements on e.id_agreement = agreements.id_agree                ` +  
                `        LEFT JOIN views.V_Ekbs_Pidpr_All dic_pidpr on dic_pidpr.id_vsp = agreements.id_saller                    ` +
                `      ) childExecutor on childExecutor.parent_id_executor = executors.id_executor                                ` +
                `  /*                                                                                                       ` +
                `   LEFT JOIN (                                                                                             ` +                                                     
                `    SELECT a.id_service                                                                                    ` +
                `            , GROUP_CONCAT( a.name_short SEPARATOR ', ') AS listExecutorsPlan                              ` +
                `    FROM                                                                                                   ` +                                                  
                `        (SELECT distinct e.id_service                                                                      ` +                                          
                `                , dic_pidpr.name_short                                                                     ` +                                                
                `          FROM IC.Slc_Services_Executors e                                                                 ` +                                 
                `          INNER JOIN pidpr.Ekbs_Pidpr_Agreements agreements on e.id_agreement = agreements.id_agree        ` +  
                `          LEFT JOIN views.V_Ekbs_Pidpr_All dic_pidpr on dic_pidpr.id_vsp = agreements.id_saller            ` +   
                `          ) a                                                                                              ` +                                                  
                `    GROUP BY a.id_service ) sallers on sallers.id_service = services.id_service                            ` +
                `   */                                                                                                      ` +
                `  LEFT JOIN (                                                                                              ` +                                                           
                `    SELECT a.id_service                                                                                    ` +
                `            , GROUP_CONCAT( a.id_executor SEPARATOR '_') AS idExecutor                                     ` +       
                `    FROM                                                                                                   ` +                                                       
                `        (SELECT distinct e.id_service, e.id_executor                                                       ` +              
                `          FROM IC.Slc_Services_Executors e                                                                 ` +
                `          ) a                                                                                              ` +                                                                                
                `    GROUP BY a.id_service ) concatData on concatData.id_service = services.id_service                      ` +

                `  WHERE services.date_plan_beg BETWEEN STR_TO_DATE('${dateBeg}', '%d.%c.%Y') AND STR_TO_DATE('${dateEnd}', '%d.%c.%Y')     ` +

                `  order by 6 "` ;

                
    return axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
    .then(res => {
        if(res.status === 200) {  
            console.log("getExeServicesByPeriod", res.data);    
            dispatch(setExeServices(res.data));
        }          
    })
    .catch((error) => {      
        // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })
    .finally(() => dispatch(setLoading(false)) );
  };

// --------Перелік фактю подій
export const getExeActions = async ( idExecutor ) => {
  dispatch(setLoading(true));
  const data =`"SELECT                                  ` +
              `    fact.id_fact as id                   ` +
              `  , fact.id_executor as idExecutor       ` +
              `  , fact.id_service as idService         ` +
              `  , fact.date_action as dateAction       ` +
              `  , fact.amount as amount                ` +
              `  , fact.amount_all as amountAll         ` +
              `  , fact.percent as percent              ` +
              `  , fact.note as note                    ` +
              `  FROM IC.Slc_Services_Fact fact         ` +
              `  WHERE fact.id_executor in (${idExecutor})    "` ; 

  return axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
    .then(res => {
        if(res.status === 200) {  
            console.log("getExeActions", res.data);    
            dispatch(setExeActions(res.data));
        }          
    })
    .catch((error) => {      
        // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })
    .finally(() => dispatch(setLoading(false)) );

};


// --------Відкрити вікно ознак виконання
// 
export const openExeModal = async (service = {}) => { 
  dispatch(setExeService(service));
  console.log("openExeModal service", service);
  getExeActions(service.idExecutor);
  
  dispatch(setExeModal(true));
};
// --------Закрити вікно ознак виконання
// 
  export const closeExeModal = async () => { 
    dispatch(setExeModal(false));
  };