import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';

const initialState = {
    isLoading: false,
    isOpenModal: false,
    error: null,
    dicStates: [],
    offers: [],
    offerService: null,
    isOpenServiceViewModal: false,
    isApprove: false,
    dicCurrency: [],
    dicActual: [],
    filterModel: {items:[]}
};

const slice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
        state.isLoading = action.payload;
        },
        setOfferModal(state, action) {
          state.isOpenModal = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setDicStates(state, action) {
          state.dicStates = action.payload;
        },
        setOffers(state, action) {
        state.offers = action.payload;
        },
        setOfferService(state, action) {
          state.offerService = action.payload;
          },
        setOpenServiceViewModal(state, action) {
          state.isOpenServiceViewModal = action.payload;
        }, 
        setOpenModal(state, action) {
          state.isOpenModal = action.payload;
        }, 
        setApprove(state, action) {
          state.isApprove = action.payload;
        },
        setDicCurrency(state, action) {
          state.dicCurrency = action.payload;
        }, 
        setDicActual(state, action) {
          state.dicActual = action.payload;
        }, 
        setFilterModel(state, action) {
          state.filterModel = action.payload;
        }, 
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    setOfferModal,
    hasError,  
    setDicStates,
    setOffers,
    setOfferService,
    setOpenServiceViewModal,
    setOpenModal,
    setApprove,
    setDicCurrency,
    setDicActual,
    setFilterModel
} = slice.actions;

// --------Реєстр пропозицій
export const getOffers = async () => {
    dispatch(setLoading(true));
    await axios.get(`api-vantazh/Executors/Propos`)
        .then(res => {
          if(res.status === 200) {  
            console.log('getOffers', res.data);
            dispatch(setOffers( res.data ));                               
          }
          else {                       
            throw new Error(res.statusText || res.status);          
          }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })   
    .finally(() => dispatch(setLoading(false)) );
  }

// --------НДІ валюта
export const getDicCurrency = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_VALUT&titleOut=K_VALC&valueOut=K_VALN&all=true')
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicCurrency(res.data));
      }
      else {                   
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------класифікатор станів
export const getDicStates = async () => {  
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=status&all=true`)
  .then(res => {
    if(res.status === 200) {
      const newDataLabel = [];        
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.value, 'label': item.title})                  
      });         
      dispatch(setDicStates(newDataLabel));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  }); 
};

// --------класифікатор актуальності
export const getDicActual = async () => {  
  const dic = [];
    dic.push({'value': 1, 'label': 'Актуальні пропозиції'});
    dic.push({'value': 0, 'label': 'Ухвалено замовником'});
    dic.push({'value': -1, 'label': 'Втрачено актуальність'});
    dispatch(setDicActual(dic));
};

// --------Послуга
export const getOfferService = async ( idChoice ) => {  
  await axios.get(`api-vantazh/Executors/Propos?idChoice=${idChoice}`)
  .then(res => {
    if(res.status === 200) {  
      console.log("getOfferService", res.data);     
      dispatch(setOfferService(res.data));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
    })   
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};
// ---------------------------------
export const openServiceViewModal = async (idService) => {  
  getOfferService(idService);
  dispatch(setOpenServiceViewModal(true));
};

export const closeServiceViewModal = async () => { 
  dispatch(setOpenServiceViewModal(false));
};

// ---------------------------------
export const openModal = async (idService, isApprove = false) => {  
  dispatch(setApprove(isApprove));
  getOfferService(idService);
  dispatch(setOpenModal(true));
};

export const closeModal = async () => { 
  dispatch(setOpenModal(false));
};

// ---------Погодження 
export const approve = async (data) => {
  await axios.patch(`api-vantazh/Executors/ApprovePropos`, data)
    .then(res => {
      if(res.status === 200) {
        // console.log('approve', res.data);
        getOffers();
        dispatch(setOpenModal(false));
        toast.success(i18n.t('service.offers.card.approved'));
      }
      else {
        throw new Error(res.statusText || res.status); 
      }
    })
    .catch((error) => {
      console.log('approve error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// ---------Відхилення погодження 
export const reject = async (data) => {
  await axios.patch(`api-vantazh/Executors/RejectPropos`, data)
    .then(res => {
      if(res.status === 200) {
        // console.log('reject', res.data);
        getOffers();
        dispatch(setOpenModal(false));
        toast.success(i18n.t('service.offers.card.rejected'));
      }
      else {        
        throw new Error(res.statusText || res.status); 
      }
    })
    .catch((error) => {
      console.log('reject error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// ---------Встановлення фильтрів
export const persistFilters = async (data) => {
  dispatch(setFilterModel(data));
};