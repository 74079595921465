/* eslint-disable prefer-const */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { addHeaderHttp, isValidToken, setSession } from '../utils/jwt';
import useAuth from '../hooks/useAuth';
import useLocales from '../hooks/useLocales';
// import navConfig from '../layouts/dashboard/navbar/NavConfig';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// ----------------------------------------------------------------------

import SvgIconStyle from '../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const initialState = {
  isInitialized: false,
  profiles: null,
  currentProileInd: 0,
  currentCabinetInd: 0,
  currentServiceInd: 0,
  currentFunctionInd: 0,
  vMenu: [],
  user: null,
  partners: [],
  typesOfStructuralUnit: [],
  typesOfEnterprise: [],
  typeServicesList: [],
  typeResourcesList: [],
  enterprises: []
};

const handlers = {
    INITIALIZE: (state, action) => {
      const { profiles, vMenu } = action.payload;
      return {
        ...state,
        isInitialized: true,
        profiles,
        vMenu
      };
    },
    LOAD: (state, action) => {
      const { profiles, vMenu, user, currentProileInd, currentCabinetInd, currentServiceInd, currentFunctionInd } = action.payload;
  
      return {
        ...state,
        isInitialized: true,
        profiles,
        vMenu,
        currentProileInd, 
        currentCabinetInd,
        currentServiceInd,
        currentFunctionInd,
        user
      };
    },
    SETPARTNERS: (state, action) => ({
        ...state,
        partners: action.payload
      }),
    SETINDEXPROFILE: (state, action) => {
      const { currentProileInd } = action.payload;
      const profileSetting = {
        currentProileInd,
        currentCabinetInd: state.currentCabinetInd
      };
      window.localStorage.setItem('profileSetting', JSON.stringify(profileSetting));

      return {
        ...state,
        currentProileInd
      };
    },
    SETINDEXCABINET: (state, action) => {
      const { currentCabinetInd } = action.payload;
      const profileSetting = {
        currentProileInd: state.currentProileInd,
        currentCabinetInd
      };
      window.localStorage.setItem('profileSetting', JSON.stringify(profileSetting));
      return {
        ...state,
        currentCabinetInd,
      };
    },
    SETINDEXSERVICE: (state, action) => {
      const { currentServiceInd } = action.payload;
      const menuSetting = {
        currentServiceInd,
        currentFunctionInd: state.currentFunctionInd
      };
      window.localStorage.setItem('menuSetting', JSON.stringify(menuSetting));

      return {
        ...state,
        currentServiceInd
      };
    },
    SETINDEXFUNCTION: (state, action) => {
      const { currentFunctionInd } = action.payload;
      const menuSetting = {
        currentServiceInd: state.currentServiceInd,
        currentFunctionInd
      };
      window.localStorage.setItem('menuSetting', JSON.stringify(menuSetting));
      return {
        ...state,
        currentFunctionInd,
      };
    },
    SETVMENU: (state, action) => {
      const { vMenu } = action.payload;
      return {
        ...state,
        vMenu,
      };
    },
    UPDATEUSER: (state, action) => {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    },
    // helpers
    setTypesOfStructuralUnit: (state, action) => ({
        ...state,
        typesOfStructuralUnit: action.payload
      }),
    setTypesOfEnterprise: (state, action) => ({
        ...state,
        typesOfEnterprise: action.payload
      }),
    setTypeServicesList: (state, action) => ({
        ...state,
        typeServicesList: action.payload
      }),
    setTypeResourcesList: (state, action) => ({
        ...state,
        typeResourcesList: action.payload
      }),
    setEnterprises: (state, action) => ({
        ...state,
        enterprises: action.payload
      })
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ProfileContext = createContext({
    ...initialState,
    load: () => Promise.resolve(),
    setMenu: () => Promise.resolve(),
    setIndProfile: () => Promise.resolve(),
    setIndCabinet: () => Promise.resolve(),
    setIndService: () => Promise.resolve(),
    setIndFunction: () => Promise.resolve(),
    updateUser: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    loadPartners: () => Promise.resolve()
  });

// ----------------------------------------------------------------------

ProfileProvider.propTypes = {
    children: PropTypes.node,
  };

// ---------------------------------------------------------------------  

function ProfileProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user } = useAuth();
    const { translate } = useLocales();
      // !!!! перенесен для использованиея разных языков
  const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking'),
  };  

    useEffect(() => {
      const initialize = async () => {
        try {
          const profileSetting = {
            currentProileInd: 0,
            currentCabinetInd: 0
          };
          const menuSetting = {
            currentServiceInd: 0,
            currentFunctionInd: 0
          };
          const sProfileSetting = window.localStorage.getItem('profileSetting');
          const sMenuSetting = window.localStorage.getItem('menuSetting');
          if (!sMenuSetting) {
            window.localStorage.setItem('menuSetting', JSON.stringify(menuSetting));
          }
          if (!sProfileSetting) {
            window.localStorage.setItem('profileSetting', JSON.stringify(profileSetting));
          }
          else
          {
            console.log(sProfileSetting);
            const p = JSON.parse(sProfileSetting);
            const { currentProileInd, currentCabinetInd } = p;
            const m = JSON.parse(sMenuSetting);
            const { currentServiceInd, currentFunctionInd } = m;
            
            profileSetting.currentProileInd = currentProileInd;
            profileSetting.currentCabinetInd = currentCabinetInd;
            state.currentProileInd = currentProileInd;
            state.currentCabinetInd = currentCabinetInd;

            menuSetting.currentServiceInd = currentServiceInd;
            menuSetting.currentFunctionInd = currentFunctionInd;
            state.currentServiceInd = currentServiceInd;
            state.currentFunctionInd = currentFunctionInd;
          }
          load(user.sub);
          console.log(state);
          getTypesOfStructuralUnit();
          getTypesOfEnterprise();
          getTypeServicesList();
          getTypeResourcesList();
          getEnterprises();
  
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isInitialized: true,
                profiles: [],
                currentProileInd: profileSetting.currentProileInd, 
                currentCabinetInd: profileSetting.currentCabinetInd,
                currentServiceInd: menuSetting.currentServiceInd, 
                currentFunctionInd: menuSetting.currentFunctionInd
              },
            });
          
        } catch (err) {
          console.error(err);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isInitialized: false,
              profiles: [],
              currentProileInd: 0,
              currentCabinetInd: 0,
              currentServiceInd: 0, 
              currentFunctionInd: 0
            },
          });
        }
      };
  
      initialize();
    }, [user.sub]);
    // ++++ metodth +++++++++++++++++++++++++++++++++++++
    const load = async (guid) => {
      // https://dev.vantazhplus.co.ua//api-sys/users/get_user_info?guid=60b262e2-30a4-4c9b-bde1-4999f2245052
      await axios.get(`/api-sys/users/get_user_info?guid=${guid}`)
        .then(res => {
          if (res.status === 200 && res.data[0]) {
            const { user } = {user: res.data[0]};
            if (user.email === 'demo@vp.co.ua') user.isGuest = true;
            else user.isGuest = false;
            const { profiles } = user;
            const currentProileInd = profiles.length<=state.currentProileInd ? 0 : state.currentProileInd; 
            const currentCabinetInd = [state.currentProileInd]?.cabinets?.length<=state.currentCabinetInd ? 0 : state.currentCabinetInd; 
            state.currentProileInd = currentProileInd;
            state.currentCabinetInd = currentCabinetInd;
            const edrpou = 
              (profiles 
              && profiles[currentProileInd] 
              && profiles[currentProileInd].ekbsPidpr 
              && profiles[currentProileInd].ekbsPidpr.codeEdr)
              ? profiles[currentProileInd].ekbsPidpr.codeEdr : "0";
            addHeaderHttp('edrpouVsp', edrpou);  
            addHeaderHttp('userId', user.userId);
            addHeaderHttp('profileId',profiles[state.currentProileInd].profileId);
            addHeaderHttp('idPidpr', profiles[state.currentProileInd].ekbsPidpr.idPidpr);
            addHeaderHttp('idVsp', profiles[state.currentProileInd].ekbsPidpr.idVsp);
            const valHeader = { 'edrpouVsp': edrpou, 'userId': user.userId, 'profileId': profiles[state.currentProileInd].profileId, 
              'idPidpr': profiles[state.currentProileInd].ekbsPidpr.idPidpr, 'idVsp': profiles[state.currentProileInd].ekbsPidpr.idVsp };
            window.localStorage.setItem('storageHeader', JSON.stringify(valHeader));
    
            // console.log(profiles.length,state.currentProileInd);
        // if(profiles.length>=state.currentProileInd) setIndProfile(0);
            // if(profiles[state.currentProileInd]?.cabinets>=state.currentCabinetInd) setIndCabinet(0);
            const vMenu = loadMenu(profiles)
            dispatch({
              type: 'LOAD',
              payload: {
                profiles,
                vMenu,
                currentProileInd, 
                currentCabinetInd,
                currentServiceInd: state.currentServiceInd,
                currentFunctionInd: state.currentFunctionInd,
                user
              },
            });
            const { officeId } = profiles[state.currentProileInd];
            loadPartners(officeId);
          }
        });
    };

    const loadPartners = async officeId => {
      await axios.get(`/api-sys/pidpr/get_partners?pId=${officeId}`)
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: 'SETPARTNERS',
              payload: res.data
            })
          }
        })
    }
  
    // eslint-disable-next-line arrow-body-style
    const convertBlobToBase64 = async (blob) => { 
      // eslint-disable-next-line no-return-await
      return await blobToBase64(blob);
    }
    
    const blobToBase64 = blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    // ==========================================================

    const updateUser = async (pUser) => {
      if (typeof(pUser.photo) !== 'string') {
        const b64 = convertBlobToBase64(pUser.photo);
        await b64.then((result) => { pUser.photo = result; } );
      }
      const response = await axios.post(`/api-sys/users/set_user_info`, pUser);
      if (response.data.code !== 0) { console.log(response.data); return -1; }
      dispatch({
        type: 'UPDATEUSER',
        payload: {
          user: pUser
        },
      });
      return 0;

    };
    // ==========================================================
    const changePassword = async (passwords) => {
      console.log(passwords)
      const config = { headers: {'Content-Type': 'application/json'} };
      const response = await axios.put(`/api-admin/admin/users/${passwords.userId}/reset-password`, passwords.newPassword, config)
      // const response = await axios.post(`/api-sys/users/change_password`, passwords);
      if (response.data.code !== 0) { return -1; }
      return 0;
    };
    // ==========================================================
    const setIndProfile = async (ind) => {
      state.currentProileInd = ind;
      const currentProileInd = ind;
      dispatch({
        type: 'SETINDEXPROFILE',
        payload: {
          currentProileInd,
        },
      });
      const vMenu = loadMenu(state.profiles);
      // console.log(vMenu);
      dispatch({
        type: 'SETVMENU',
        payload: {
          vMenu
        },
      });
      const { officeId } = state.profiles[ind];
      loadPartners(officeId);
    }
    const setIndCabinet = async (ind) => {
      state.currentCabinetInd = ind;
      const currentCabinetInd = ind;
      dispatch({
        type: 'SETINDEXCABINET',
        payload: {
          currentCabinetInd,
        },
      });
      const vMenu = loadMenu(state.profiles);
      // console.log(vMenu);
      dispatch({
        type: 'SETVMENU',
        payload: {
          vMenu
        },
      });
    }
    const setMenu = async () => {
      const vMenu = loadMenu(state.profiles);
      // console.log(vMenu);
      dispatch({
        type: 'SETVMENU',
        payload: {
          vMenu
        },
      });

    }
    // -----------------------------------------------------------------------
        // ==========================================================
        const setIndService = async (ind) => {
          state.currentServiceInd = ind;
          const currentServiceInd = ind;
          dispatch({
            type: 'SETINDEXSERVICE',
            payload: {
              currentServiceInd,
            },
          });
    
        }
        const setIndFunction = async (ind) => {
          state.currentFunctionInd = ind;
          const currentFunctionInd = ind;
          dispatch({
            type: 'SETINDEXFUNCTION',
            payload: {
              currentFunctionInd,
            },
          });
        }
    // -----------------------------------------------------------------------
    const loadMenu = (profiles) => {
      const navConfig = [];
      /*
        {
          subheader: translate('vmenu.profiles.header'),
          items: [
            {
              title: translate('vmenu.profiles.user'),
              path: PATH_DASHBOARD.user.root,
              icon: ICONS.user,
              children: [
                { title: translate('vmenu.profiles.profile'), path: PATH_DASHBOARD.user.profile },
                { title: translate('vmenu.profiles.mydata'), path: PATH_DASHBOARD.user.account },
                { title: translate('vmenu.profiles.bugs'), path: PATH_DASHBOARD.user.bugs },
              ],
            },
            {
              title: translate('vmenu.profiles.pidpr'),
              path: PATH_DASHBOARD.pidpr.root,
              icon: ICONS.ecommerce,
              children: [
                { title: translate('vmenu.profiles.dogovor'), path: PATH_DASHBOARD.pidpr.agreements },
                { title: translate('vmenu.profiles.allpidpr'), path: PATH_DASHBOARD.pidpr.enterprises },
              ],
    
            },
            {
              title: translate('vmenu.profiles.posts'),
              path: PATH_DASHBOARD.posts.root,
              icon: ICONS.blog,
              children: [
                { title: translate('vmenu.profiles.mypost'), path: PATH_DASHBOARD.posts.mypublications },
                { title: translate('vmenu.profiles.allpost'), path: PATH_DASHBOARD.posts.allpublications },
              ],
    
            }
          ],
        }
      ];
      */
    
      let menu = [];
      let service = { subheader: translate('vmenu.header'), items: [] };
      
      if (profiles) {

        addHeaderHttp('edrpouVsp', profiles[state.currentProileInd].ekbsPidpr.codeEdr);  
        addHeaderHttp('profileId',profiles[state.currentProileInd].profileId);
        addHeaderHttp('idPidpr', profiles[state.currentProileInd].ekbsPidpr.idPidpr);
        addHeaderHttp('idVsp', profiles[state.currentProileInd].ekbsPidpr.idVsp);
        const valHeader = { 'edrpouVsp': profiles[state.currentProileInd].ekbsPidpr.codeEdr, 'userId': user.userId, 'profileId': profiles[state.currentProileInd].profileId, 
              'idPidpr': profiles[state.currentProileInd].ekbsPidpr.idPidpr, 'idVsp': profiles[state.currentProileInd].ekbsPidpr.idVsp };
            window.localStorage.setItem('storageHeader', JSON.stringify(valHeader));
  
        service.items = (profiles[state.currentProileInd]?.cabinets[state.currentCabinetInd]?.appList.map((item) => (
          item.appActions.length > 1 ?
          { title: translate(`vmenu.services.${item.mncode.toUpperCase()}`) || item.name, 
            path: ['/ekbs/services',item.mncode.toLowerCase()].join('/'), 
            icon: getIcon(item.mncode.toLowerCase()), 
            children: 
              item.appActions.map((action) => ( { title: translate(`vmenu.actions.${action.mncode.toUpperCase()}`) || action.name, 
                                                  path: ['/ekbs/services',item.mncode.toLowerCase(),action.mncode.toLowerCase()].join('/'), 
                                                  icon: getIcon(item.mncode.toLowerCase()) } ))
          } :
          { title: translate(`vmenu.services.${item.mncode.toUpperCase()}`) || item.name, path: ['/ekbs/services',item.mncode.toLowerCase(),item.appActions[0].mncode.toLowerCase()].join('/'), icon: getIcon(item.mncode.toLowerCase()) }
          )));
          menu.push(service);
      }  
      navConfig.map((item) => (menu.push(item)));
      return menu; 
    };
    // helpers

    const getTypesOfStructuralUnit = async () => {
      await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Pidpr_Type_Vsp&titleOut=name&valueOut=code_vsp&all=true')
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: 'setTypesOfStructuralUnit',
              payload: res.data
            });
          }
        });
    }
    
    const getTypesOfEnterprise = async () => {
      await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Pidpr_Type&titleOut=name_type&valueOut=code_type&all=true')
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: 'setTypesOfEnterprise',
              payload: res.data
            });
          }
        });
    }
    
    const getTypeServicesList = async () => {
      await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Pidpr_Services&titleOut=name_short_${localStorage.getItem('i18nextLng')}&valueOut=code_service&all=true`)
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: 'setTypeServicesList',
              payload: res.data
            });
          }
        });
    }
    
    const getTypeResourcesList = async () => {
      await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Pidpr_Type_Resources&titleOut=name_short_${localStorage.getItem('i18nextLng')}&valueOut=code_resource&all=true`)
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: 'setTypeResourcesList',
              payload: res.data
            });
          }
        });
    }

    const getEnterprises = async () => {
      await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=pidpr.Ekbs_Pidpr_All&titleOut=name_short&valueOut= id_vsp&all=true')
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: 'setEnterprises',
              payload: res.data
            });
          }
        });
    }

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    return (
      <ProfileContext.Provider
        value={{
          ...state,
          load,
          setMenu,
          setIndProfile,
          setIndCabinet,
          setIndService,
          setIndFunction,
          updateUser,
          changePassword,
          loadPartners
        }}
      >
        {children}
      </ProfileContext.Provider>
    );
  }
  
  export { ProfileContext, ProfileProvider };
