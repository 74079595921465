import { format, getTime, formatDistanceToNow, differenceInDays } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function customDateFormat(date, dateFormat = 'dd/MM/yyyy') {
  if (!date) {
    return '---'
  }
  return format(new Date(date), dateFormat);
}

export function customPointDateFormat(date, dateFormat = 'dd.MM.yyyy') {
  return format(new Date(date), dateFormat);
}

export function customPointDateFormatUS(date, dateFormat = 'MM.dd.yyyy') {
  return format(new Date(date), dateFormat);
}

export function customLocaleFormat(date, dateFormat = 'dd/MM/yyyy') {  
  return localStorage.getItem('i18nextLng') === 'en' ? 
    format(new Date(date), 'MM/dd/yyyy') : format(new Date(date), dateFormat);
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function customDateTime(date) {
  return format(new Date(date), 'dd.MM.yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function substractDate(date, type, count) {
  return moment(date).subtract(count, type);
}

export function getNumberOfDays(start, end) { 
  const date1 = new Date(start); 
  const date2 = new Date(end); 
  
  return differenceInDays(date2, date1);
}

export function getDatesBetweenDates(startDate, endDate, formatStr = 'dd/MM/yyyy') {
  let dates = []
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate]
  return dates.map(date => format(date, formatStr))
}

export function getMonthsBetweenDates(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const dates = [];
  start.subtract(1, "month");

  const month = moment(start);
  while( month < end ) {
      month.add(1, "month");
      dates.push(month.format('MM/YYYY'));
  }
  return dates;
}

export function getPeriodByMonth(startDate, endDate) {
  const daysArr = getDatesBetweenDates(startDate, endDate);
  const monthsArr = getMonthsBetweenDates(startDate, endDate);
  const res = {};
  monthsArr.forEach(month => {
    res[month] = [];
  })
  Object.keys(res).map(item => daysArr.forEach(day => {
      const compareMonth = `${day.split('/')[1]}/${day.split('/')[2]}`
      if (item === compareMonth) {
        res[item].push(day);
      }
    })
  )
  return res;
}