import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
// import getLangSuffix from '../../locales/getLangSuffix';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';




const initialState = {
    isLoading: false,
    isTemp: false,
    isOpenModal: false,
    isOpenSwitchModal: false,
    isOpenPeriodModal: false,
    error: null,
    dicStates: [],
    dicStatusApprove: [],
    dicCanApprove: [],
    contracts: [],  
    tmpContracts: [] , tmpRoutes: [], 
    contract : null,
    dicPidprAll: [],
    dicStations: [],
    dicStationsUkr: [],
    dicStationsAll: [],
    dicCargoETSNV:[],
    dicCargoGNG:[],
    dicTypeContract:[],
    dicAdm: [],
    dicForwarders: [],  
    dicPidprElevator: [],
    isOpenContractViewModal: false, 
    dicBasis: [],
    dicRecepients: [],
    dicLLMApprove: [],
   
    cargoParts: [],
    cargoSends: [],
    ksppElement: null,
    contractServices: null,
    contractRoutes: [],
    contractNLL: [],
    nllDetails: null,
    statementInstructionRoutes: [],
    statementInstructionByRoute: null
  };

const slice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
        state.isLoading = action.payload;
        },
        setTemp(state, action) {
          state.isTemp = action.payload;
          },
        setContractModal(state, action) {
          state.isOpenModal = action.payload;
          },
        setOpenContractViewModal(state, action) {
            state.isOpenContractViewModal = action.payload;
        },
        setSwitchModal(state, action) {
          state.isOpenSwitchModal = action.payload;
          },
        setPeriodModal(state, action) {
            state.isOpenPeriodModal = action.payload;
            },
         // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
        setDicStates(state, action) {
          state.dicStates = action.payload;
        },     
        setDicStatusApprove(state, action) {
          state.dicStatusApprove = action.payload;
        }, 
        setDicCanApprove(state, action) {
          state.dicCanApprove = action.payload;
        },                     
        setContracts(state, action) {
        state.contracts = action.payload;
        },
        setTmpContracts(state, action) {
          state.tmpContracts = action.payload;
        },
        setTmpRoutes(state, action) {
          state.tmpRoutes = action.payload;
        },
        setContract(state, action) {
        state.contract = action.payload;
        },        
        setKsppElement(state, action) {
          state.ksppElement = action.payload
        },  
        setDicPidprAll(state, action) {
        state.dicPidprAll = action.payload;
        }, 
        setDicPidprElevator(state, action) {
          state.dicPidprElevator = action.payload;
          },  
        setDicAdm(state, action) {
          state.dicAdm = action.payload;
        },             
        setDicStations(state, action) {
        state.dicStations = action.payload;
        }, 
        setDicStationsUkr(state, action) {
          state.dicStationsUkr = action.payload;
        },   
        setDicStationsAll(state, action) {
          state.dicStationsAll = action.payload;
        },           
        setDicCargoETSNV(state, action) {
        state.dicCargoETSNV = action.payload;
        },
        setDicCargoGNG(state, action) {
          state.dicCargoGNG = action.payload;
        },
        setDicTypeContract(state, action) {
        state.dicTypeContract = action.payload;
        },  
        setDicLLMApprove(state, action) {
          state.dicLLMApprove = action.payload;
          },              
        setCargoParts(state, action) {
        state.cargoParts = action.payload;
        },        
        setCargoSends(state, action) {
        state.cargoSends = action.payload;
        },        
        setDicForwarders(state, action) {
        state.dicForwarders = action.payload;
        },
        setDicBasis(state, action) {
          state.dicBasis = action.payload;
        },
        setDicRecepients(state, action) {
          state.dicRecepients = action.payload;
        },
        setContractServices(state, action) {
          state.contractServices = action.payload;
        },
        setContractRoutes(state, action) {
          state.contractRoutes = action.payload;
        },
        setContractNLL(state, action) {
          state.contractNLL = action.payload;
        },
        setNllDetails(state, action) {
          state.nllDetails = action.payload;
        },
        setStatementInstructionRoutes(state, action) {
          state.statementInstructionRoutes = action.payload;
        },
        setStatementInstructionByRoute(state, action) {
          state.statementInstructionByRoute = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    setTemp,
    setContractModal,
    setSwitchModal,
    setPeriodModal,
    hasError,    
    setDicStates,
    setDicStatusApprove, setDicCanApprove,
    setContracts,
    setTmpContracts, setTmpRoutes,
    setContract,
    setKsppElement,
    setDicPidprAll,
    setDicPidprElevator,
    setDicAdm,
    setDicStations,
    setDicStationsUkr,
    setDicStationsAll,
    setDicCargoETSNV,
    setDicCargoGNG,
    setDicTypeContract,
    setDicForwarders,
    setOpenContractViewModal,
    setDicBasis,
    setDicRecepients,
    setDicLLMApprove,
    
    setCargoParts,
    setCargoSends,
    setContractServices,
    setContractRoutes,
    setContractNLL,
    setNllDetails,
    setStatementInstructionRoutes,
    setStatementInstructionByRoute
  } = slice.actions;


// --------класифікатор станів
export const getDicStates = async () => { 
  // console.log('getLangSuffix:', getLangSuffix());
  // console.log('lang:', localStorage.getItem('i18nextLng')); 
  
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=status&all=true`)
  .then(res => {
    if(res.status === 200) {
      const newDataLabel = [];  
      const newDataLabelApprove = [];      
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.value, 'label': item.title});
        if ((Number(item.value)>0 &&  Number(item.value)<4) ||  Number(item.value) === 9)   
          newDataLabelApprove.push({'value': Number(item.value), 'label': item.title});           
      });
      console.log('getDicStates:', newDataLabel);          
      dispatch(setDicStates(newDataLabel));
      dispatch(setDicStatusApprove(newDataLabelApprove)); 
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {
    // console.log(error);
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
}; 
/*
// --------класифікатор станів узгодження
export const getDicStatusApprove = async () => { 
  const dic = [];
  dic.push({'value': 1, 'label': 'Проєкт'});
  dic.push({'value': 2, 'label': 'На узгодженні'});
  dic.push({'value': 3, 'label': 'Узгоджено'});
  dispatch(setDicStatusApprove(dic));  
}; 
*/
// --------класифікатор можливості узгодження
export const getDicCanApprove = async () => { 
  const dic = [];
  dic.push({'value': true, 'label': i18n.t('service.contracts.columns.yes')}); 
  // dic.push({'value': false, 'label': 'Ні'});
  dispatch(setDicCanApprove(dic));  
}; 

// --------класифікатор базисов собственности
export const getDicBasis = async () => {  
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Contract_Basis&titleOut=name_basis_${localStorage.getItem('i18nextLng')}&valueOut=code_basis&all=true`)
  .then(res => {
    if(res.status === 200) {
      // console.log('getDicBasis', res.data);
      dispatch(setDicBasis(res.data));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {
    // console.log(error);
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};  

// --------класифікатор підприємств із зазначенням наявності коду вантажоодержувача (згідно заув. 56)
export const getDicRecepients = async () => {  
  await axios.get(`/api-vantazh/contracts/pidprs`)
  .then(res => {
    if(res.status === 200) {
      // console.log('getDicRecepients', res.data);
      dispatch(setDicRecepients(res.data));      
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {
    // console.log(error);
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
}; 

// --------класифікатор кодів виконання
export const getDicLLMApprove = async () => {  
  const dic = [];
  dic.push({'value': 1, 'label': i18n.t('service.contracts.columns.exeStatus')});
  // dic.push({'value': 0, 'label': '-'});
  dispatch(setDicLLMApprove(dic));
  
};

// --------Реєстр контрактів

export const getContracts = async (withLoader = true) => {
      if (withLoader) dispatch(setLoading(true));
      // ****************************
      /*
      await axios.get(`api-vantazh/contracts/Route?withGroups=true`)
            .then(res => {
              if(res.status === 200) {  
                console.log('tmpRoutes', res.data);
                dispatch(setTmpRoutes(res.data));                               
              }
              else {          
                throw new Error(res.statusText || res.status);          
              }  
        })
        .catch((error) => {      
          toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true});
          setLoading(false); 
        }) ;
      */
      // ****************************
      // await axios.get(`api-vantazh/contracts/get_all/${enterpriseId}/0/100`)
      // await axios.get(`api-vantazh/contracts/getContracts?enterpriseId=${enterpriseId}`)
      // await axios.get(`api-vantazh/contracts/Contract?enterpriseId=${enterpriseId}`)
      await axios.get(`api-vantazh/contracts/Contract`)
        .then(res => {
          if(res.status === 200) {  
            console.log('contracts', res.data); 
            dispatch(setContracts( res.data)); 
            /*
            const updatedData = res.data.map(item => ({ 
              ...item, 
              canApprove: item.canApprove === true ? 1 : 0
            }));           
            dispatch(setContracts( updatedData)); 
            console.log('contracts updatedData', updatedData);
             */                            
          }
          else {      
            throw new Error(res.statusText || res.status);          
          }
    }) 
    .catch((error) => {
      // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      
    })   
    .finally(() => dispatch(setLoading(false)) );

    
}

// --------контракт

export const openContractModal = async (idContract) => {
  getContract(idContract);
  dispatch(setContractModal(true));
};
/*
export const openContractModal = async (contract = {} ) => {
    dispatch(setContract(contract));
    dispatch(setContractModal(true));
};
*/
export const closeContractModal = async () => { 
  dispatch(setContractModal(false));
};

export const openSwitchModal = async (isOpen) => { 
  // dispatch(setContract(null));        
  dispatch(setSwitchModal(isOpen));
};

/*
export const createNewContract = async () => { 
  dispatch(setContract(null));  
};
*/
export const openNewContractModal = async () => {
  getNewContract();
  dispatch(setContractModal(true));
};

export const getNewContract = async () => {
  dispatch(setContract(null));  
  dispatch(setTemp(true));
};
/*
export const persistStateTemp = async (flag) => {   
  dispatch(setTemp(flag));
};
*/

export const getContract = async (idContract) => {  
  // await axios.get(`api-vantazh/contracts/get_item/${idContract}`)
  await axios.get(`api-vantazh/contracts/AllContract?idContract=${idContract}`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setContract(res.data)); 
        dispatch(setTemp(true));         
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const clearContract = () => {
  dispatch(setContract(null));
}

export const getKsppElement = async id => {
  await axios.get(`api-vantazh/api/v1/Kspp/KsppElement?id_element=${id}`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setKsppElement(res.data))         
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
}

export const getContractServices = async (idContract) => {  
  await axios.get(`api-vantazh/visual/contract/${idContract}/services`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setContractServices(res.data));          
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const getContractRoutes = async (idContract) => {  
  await axios.get(`api-vantazh/KeyServices/Routes/${idContract}`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setContractRoutes(res.data));          
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const getContractNNL = async (idContract) => {  
  await axios.get(`api-vantazh/Rlcs/Contract/${idContract}`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setContractNLL(res.data));          
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const getNllDetails = async (idRlc) => {  
  await axios.get(`api-vantazh/Rlcs/${idRlc}`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setNllDetails(res.data));          
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const getStatementInstructionRoutes = async (idContract) => {  
  await axios.get(`api-vantazh/visual/contract/${idContract}/route`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setStatementInstructionRoutes(res.data.data));          
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    console.log(error)
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const getStatementInstructionByRoute = async (idContract, idRoute) => {  
  await axios.get(`api-vantazh/visual/contract/${idContract}/route/${idRoute}`)
    .then(res => {
      if(res.status === 200) { 
        dispatch(setStatementInstructionByRoute(res.data.data));          
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    console.log(error)
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

export const getContractInfo = async (idContract) => {  
  // await axios.get(`api-vantazh/contracts/get_item/${idContract}`)
  await axios.get(`api-vantazh/contracts/Contract?idContract=${idContract}`)
    .then(res => {
      if(res.status === 200) {
        // console.log('getContractInfo', res.data);
        dispatch(setContract(res.data));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------НДІ Підприємства
export const getDicPidprAll = async () => {
  // await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=views.V_Ekbs_Pidpr_All&titleOut=display_info&valueOut= id_vsp&all=true`)
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Ekbs_Pidpr_All&fieldOutOne=display_info&fieldOutTwo=id_vsp&fieldOutThree=k_strn&all=true`)
  
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicPidprAll', res.data);
        // res.data=res.data?.sort((a, b) => -b.title.firstLetter?.localeCompare(a.title.firstLetter))
        // dispatch(setDicPidprAll(res.data));
       
        const newData = [];        
        res.data.forEach(item => { 
          const newItem = {'value': item.field2, 'title': item.field1.trim(), 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                     
        });  
        // console.log('getDicPidprAll', newData);       
        dispatch(setDicPidprAll(newData));
        
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
  };

// --------НДІ Підприємства (елеватор)
export const getDicPidprElevator = async (idPart = 0) => {
  // console.log('idPart', idPart);
  // await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=views.V_Ekbs_Pidpr_All&titleOut=display_info&valueOut=id_vsp&&value=4&searchField=TYPE_PIDPR&all=false&limit=100`)
  await axios.get(`/api-vantazh/contracts/Parts/Elevators/${idPart}`)
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicPidprElevator', res.data);
        dispatch(setDicPidprElevator(res.data));       
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};
// --------НДІ адміністрація призначення
/*
export const getDicAdm = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_ADM&titleOut=N_ADMR&valueOut=K_ADMI&all=true')
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.value, 'title': `${item.title} (${item.value})`};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicAdm(newData));
      }
    })        
};
*/
export const getDicAdm = async () => {
await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_D_ADM&fieldOutOne=N_ADMR_${localStorage.getItem('i18nextLng')}&fieldOutTwo=K_ADMI&fieldOutThree=k_strn&all=true`)
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_ADM&titleOut=N_ADMR&valueOut=K_ADMI&all=true')
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          // const newItem = {'value': item.value, 'title': `${item.title} (${item.value})`};
          const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicAdm(newData));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
  };  

// --------НДІ Станції
export const getDicStations = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP_OSZ_GR&titleOut=N_RPR_${localStorage.getItem('i18nextLng')}&valueOut=K_RP&all=true`)
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value})`};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });  
        // newData.push( {value: 0, title: ''} );       
        dispatch(setDicStations(newData));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });       
};

export const getDicStationsUkr = async () => {
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU&valueOut=K_RP&all=true')  
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU_${localStorage.getItem('i18nextLng')}&valueOut=K_RP&&value=СТ&searchField=M_SPRPR&all=false&limit=2000`)
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value})`};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          } 
          else {      
            throw new Error(res.statusText || res.status);          
          }                  
        }); 
        // newData.push( {value: 0, title: ''} );        
        dispatch(setDicStationsUkr(newData));
      }
    })
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};
export const getDicStationsAll = async () => {
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info_${localStorage.getItem('i18nextLng')}&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          } 
          else {      
            throw new Error(res.statusText || res.status);          
          }                  
        });  
        dispatch(setDicStationsAll(newData));
      }
    })
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

// --------НДІ Вантаж
export const getDicCargoETSNV = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_TV_ETSNV&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=code&all=true`)
  .then(res => {
    if(res.status === 200) {      
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value})`};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }
          else {      
            throw new Error(res.statusText || res.status);          
          }                   
        });         
        dispatch(setDicCargoETSNV(newData));
    }
  }) 
  .catch((error) => {    
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  }); 
};

export const getDicCargoGNG = async () => {
  // await axios.get(`/api-nsi/api/v1/DataNsi/GetConsignorValues?table=nsi.E_TV_GNV&titleOut=NVS_G&valueOut=KV_GNG&edrpouOut=KV_GNG&codeOut=KV&all=true`)
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_TV_GNV&fieldOutOne=NVS_G_${localStorage.getItem('i18nextLng')}&fieldOutTwo=KV_GNG&fieldOutThree=KV&all=true`)
  .then(res => {
    if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => { 
          
          const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }  
          else {      
            throw new Error(res.statusText || res.status);          
          }                 
        });         
        dispatch(setDicCargoGNG(newData));
    }
  })
  .catch((error) => {    
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------НДІ вид контракту 
export const getDicTypeContract = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Contract&titleOut=name_type_${localStorage.getItem('i18nextLng')}&valueOut=code_type&all=true`)
  .then(res => {
    if(res.status === 200) {
      dispatch(setDicTypeContract(res.data));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {    
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });
};

// --------НДІ експедитора-логіста
/* Гена
select 
distinct p.id_vsp, p.display_info
from views.V_Ekbs_Pidpr_All p,
     pidpr.Ekbs_Pidpr_Agreements pa,
     pidpr.Ekbs_Agreements_Services ps
where pa.id_customer in (158,160) and pa.id_agree = ps.id_agree and ps.code_services = 44
  and pa.id_saller = p.id_vsp
*/
/* я
select 
distinct p.id_vsp, p.display_info, pa.id_customer
from views.V_Ekbs_Pidpr_All p,
     pidpr.Ekbs_Pidpr_Agreements pa,
     pidpr.Ekbs_Agreements_Services ps
where pa.id_agree = ps.id_agree and ps.code_services = 44
  and pa.id_saller = p.id_vsp
*/
export const getDicForwarders = async () => {
  await axios.get(`api-sys/pidpr/forwarders`)
    .then(res => {
      if(res.status === 200) {
        // console.log('DicForwarders', res.data);            
        dispatch(setDicForwarders(res.data));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------видалення контракту
export const deleteContract = (idContract, nameContract) => {
    showConfirmationModal({
      isOpen: true,
      title: i18n.t('service.contracts.titleDelete'),
      content: `${i18n.t('service.contracts.answerDelete')} ${nameContract}?`,
      onSubmit: () => submitDelete(idContract),
      onCancel: () => showConfirmationModal({ isOpen: false })
    });
  };
  
  const submitDelete = async (idContract) => { 
    // await axios.get(`/api-vantazh/contracts/del_item?id=${idContract}`) 
    await axios.delete(`/api-vantazh/contracts/AllContract?idContract=${idContract}`) 
    .then(res => {
      if(res.status === 200) {
        getContracts();
        showConfirmationModal({ isOpen: false });
        toast.success(i18n.t('service.contracts.deleted'));        
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
  };

// --------збереження контракту
/*
export const saveContract = async (data) => {
  console.log(data)
  await axios.post(`/api-vantazh/contracts/set_item`, data)
    .then(res => {
      if(res.status === 200) {
        dispatch(setContracts(res.data)); 
        dispatch(setContractModal(false, null)); 
        toast.success(`Контракт '${data.nameContract}' успішно збережено.`);
      }
    });
};
*/

// --------збереження
export const saveContract = async (data, withRequestApprove) => {
  await axios.put(`api-vantazh/contracts/AllContract?withRequestApprove=${withRequestApprove}`, data)
    .then(res => {
      if(res.status === 200) {
        console.log('saveContract', res.data);
        dispatch(setContract(res.data));   
        toast.success(i18n.t('service.contracts.saved'));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      // console.log('saveContract error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження  (NEW)
export const saveNewContract = async (data, withRequestApprove) => {
  await axios.post(`api-vantazh/contracts/AllContract?withRequestApprove=${withRequestApprove}`, data)
    .then(res => {
      if(res.status === 200) {
        console.log('saveContract', res.data);
        dispatch(setContract(res.data));   
        toast.success(i18n.t('service.contracts.saved'));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      // console.log('saveNewContract error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};








//  --------

export const getPartsByContract = async (idContract) => {
  dispatch(setLoading(true));
    const f=[{
      'idContract': '147869',
      'idPart': '2',
      'namePart': 'Партія кукрудзи',
      'nameStationFrom': 'Прилуки',
      'codeStationFrom': '429002',
      'nameStationTo': 'Свіноуйсьце',
      'codeStationTo': '610147',
      'nameCargo': 'кукурудза',
      'codeCargo': '15006',
      'weight': 3500,
       countCar: 50,
      'codeState': 'Проект'
    }]
  
  dispatch(setCargoParts(f));
  dispatch(setLoading(false));
};

export const getSendsByContract = async (idContract) => {
  dispatch(setLoading(true));
    const f=[{
      'idForm': '200',
      'nameForm': 'Відправка кукрудзи',
      'dateBegin': '2022-06-01',
      'idContract': '147869',
      'nameContract': 'FORSAG-001-1',
      'idCargoParts': '10',
      'nameCargoParts': 'Партія кукрудзи',
      'nameStationFrom': 'Прилуки',
      'codeStationFrom': '429002',
      'nameStationTo': 'Свіноуйсьце',
      'codeStationTo': '610147',
      'nameCargo': 'кукурудза',      
      'weight': 3500,
       countCar: 50,
      'codeState': 1
    }]
  
  dispatch(setCargoSends(f));
  dispatch(setLoading(false));
};

// ---------------------------------
export const openContractViewModal = async (idContract) => {
  getContractInfo(idContract);
  dispatch(setOpenContractViewModal(true));
};

export const closeContractViewModal = async () => { 
  dispatch(setOpenContractViewModal(false));
};

// -------- Test WebSocket ------------
export const newEventConstracts = async (idContract, all) => {
  if (all)  getContracts();
  else getContract(idContract);
}
// ---------Погодження контракту
export const approve = async (idContract, all) => {
  await axios.patch(`api-vantazh/contracts/Approve/${idContract}`)
    .then(res => {
      if(res.status === 200) {
        console.log('approve', res.data);

        if (all)  getContracts();
        else getContract(idContract);
        
        toast.success(i18n.t('service.contracts.approved'));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      console.log('approve error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// ---------Відхилення погодження контракту
export const reject = async (idContract, all) => {
  await axios.patch(`api-vantazh/contracts/Reject/${idContract}`)
    .then(res => {
      if(res.status === 200) {
        console.log('reject', res.data);
        
        if (all)  getContracts();
        else getContract(idContract);
        
        toast.success(i18n.t('service.contracts.rejected'));
      }
      else {
        // console.log('reject res.status', res.status);
        throw new Error(res.statusText || res.status); 
      }
    })
    .catch((error) => {
      console.log('reject error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// ---------Надіслати контракт на погодження
export const moveToApprove = async (idContract) => {
  await axios.patch(`api-vantazh/contracts/MoveToApprove/${idContract}`)
    .then(res => {
      if(res.status === 200) {
        // console.log('moveToApprove', res.data);
        getContract(idContract);
        
        toast.success(i18n.t('service.contracts.card.movedToApprove'));
      }
      else {
        // console.log('movedToApprove res.status', res.status);
        throw new Error(res.statusText || res.status);
      }
    })
    .catch((error) => {
      console.log('movedToApprove error:', error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------- редагувати період дії контракту 
export const openPeriodModal = async (idContract) => {
  getContractDates(idContract);
  dispatch(setPeriodModal(true));
};

export const closePeriodModal = async () => { 
  dispatch(setPeriodModal(false));
};

export const getContractDates = async (idContract) => {  
  await axios.get(`api-vantazh/contracts/ContractDates?idContract=${idContract}`)
    .then(res => {
      if(res.status === 200) { 
        console.log('getContractDates', res.data);
        dispatch(setContract(res.data));                
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
  })     
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

// --------збереження
export const saveContractDates = async (data) => {
  await axios.patch(`api-vantazh/contracts/ContractDates`, data)
    .then(res => {
      if(res.status === 200) {
        console.log('saveContractDates', res.data);
        // dispatch(setContract(res.data));  
        dispatch(setPeriodModal(false)); 
        // или если модальное окно не закрывать - то res.data д.б. от метода getContractDates
        getContracts();
        toast.success(i18n.t('service.contracts.saved'));
      }
      else {      
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};




