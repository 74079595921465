import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';

const initialState = {
  isLoading: false,
  error: null,
  formulyars: [], 
  formulyar: null,
  dicStates: [],
  dicActions: [], 
  formulyarInfo : null,  
  formulyarPlan: [],  
  formulyarFact: [],

  dicContracts: [],
  dicCargoParts: [],
  
  planAction: null,
  isPlanActionModalOpen: false,

  formulyarActions: [],
  isCreateModalOpen: false,
  dicCargo:[],
  dicCountries: [],
  dicStations: [],
  dicRLC: [],
};

const slice = createSlice({
  name: 'formulyar',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setFormulyars(state, action) {
      state.formulyars = action.payload;
    },
    setFormulyar(state, action) {
      state.formulyar = action.payload;
    },
    setDicStates(state, action) {
      state.dicStates = action.payload;
    },
    setDicActions(state, action) {
      state.dicActions = action.payload;
    },
    setFormulyarInfo(state, action) {
      state.formulyarInfo = action.payload;
    },
    setFormulyarPlan(state, action) {
      state.formulyarPlan = action.payload;
    },
    setFormulyarFact(state, action) {
      state.formulyarFact = action.payload;
    },
    setDicContracts(state, action) {
      state.dicContracts = action.payload;
    },
    setDicCargoParts(state, action) {
      state.dicCargoParts = action.payload;
    },    
    setPlanAction(state, action) {
      state.planAction = action.payload;
    },
    setPlanActionModal(state, action) {
      state.isPlanActionModalOpen = action.payload;
    },
    setFormulyarActions(state, action) {
      state.formulyarActions = action.payload;
    },
    setCreateModalOpen(state, action) {
      state.isCreateModalOpen = action.payload;
    },        
    setDicCargo(state, action) {
    state.dicCargo = action.payload;
    },                  
    setDicCountries(state, action) {
    state.dicCountries = action.payload;
    },        
    setDicStations(state, action) {
    state.dicStations = action.payload;
    },        
    setDicRLC(state, action) {
    state.dicRLC = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setFormulyars,
  setFormulyar,
  setDicStates,
  setDicActions,
  setFormulyarInfo,
  setFormulyarPlan,
  setFormulyarFact,

  setDicContracts,
  setDicCargoParts,
  
  setPlanAction,
  setPlanActionModal,

  setFormulyarActions,

  setCreateModalOpen,
  setDicCargo,
  setDicCountries,
  setDicStations,
  setDicRLC

} = slice.actions;

export const openCreateModal = async (isOpen) => {
  dispatch(setCreateModalOpen(isOpen));
};

// --------збереження
export const saveFormulyar = async (data) => {
  toast.success(`'${data.nameForm}' успішно збережено.`);
};

// --------Реєстр формулярів 
export const getFormulyars = async (edrpou) => {
  dispatch(setLoading(true));
    const f=[
      {
        'idForm': '200',
        'nameForm': 'Відправка кукрудзи',
        'dateBegin': '2022-06-01',
        'idContract': '147869',
        'nameContract': 'FORSAG-001-1',
        'idCargoParts': '10',
        'nameCargoParts': 'Партія кукрудзи',
        'nameCargo': 'кукурудза',      
        'weight': 3500,
         countCar: 50,
        'codeState': 1
      },
      {
      'idForm': '111',
      'nameForm': 'Відправка №1',
      'dateBegin': '2022-04-24',
      'idContract': '147869',
      'nameContract': 'FORSAG-001-1',
      'idCargoParts': '2',
      'nameCargoParts': 'Партія 1 (з елєватору 145 до станції 415601)',
      'nameCargo': 'Пшениця',
      'weight': 3500,
       countCar: 50,
      'codeState': 4
    },
    {
      'idForm': '115',
      'nameForm': 'Відправка №2',
      'dateBegin': '2022-04-25',
      'idContract': '147869',
      'nameContract': 'FORSAG-001-1',
      'idCargoParts': '2',
      'nameCargoParts': 'Партія 1 (з елєватору 145 до станції 415601)',
      'nameCargo': 'Пшениця',
      'weight': 3000,
       countCar: 40,
      'codeState': 4
    },
    {
      'idForm': '112',
      'nameForm': 'Відправка №1',
      'dateBegin': '2022-04-25',
      'idContract': '147870',
      'nameContract': 'FORTUNA-002',
      'idCargoParts': '3',
      'nameCargoParts': 'Партія 3',
      'nameCargo': 'Пшениця',
      'weight': 2000,
       countCar: 33,
      'codeState': 4
    },
    {
      'idForm': '116',
      'nameForm': 'Відправка №2',
      'dateBegin': '2022-06-05',
      'idContract': '147870',
      'nameContract': 'FORTUNA-002',
      'idCargoParts': '5',
      'nameCargoParts': 'Партія 5',
      'nameCargo': 'Пшениця',
      'weight': 2500,
       countCar: 37,
      'codeState': 1
    } 
    
    
    ];
    dispatch(setFormulyars(f));
    dispatch(setLoading(false));
}

// --------класифікатор станів
export const getDicStates = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=status&all=true`)
  .then(res => {
    if(res.status === 200) {
      const newDataLabel = [];        
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.value, 'label': item.title})                  
      });         
      dispatch(setDicStates(newDataLabel));
    }
  })
  /*
  .catch((error) => {
    toast.error(`${error.title ? error.title : error }, ${error.status ? error.status : ''}`);        
  }); 
  */
};

// --------видалення формуляру
export const deleteFormulyar = (idForm, nameForm) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити '${nameForm}'?`,
    onSubmit: () => submitDelete(idForm, nameForm),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDelete = async (idForm, nameForm) => {  
      showConfirmationModal({ isOpen: false });
      toast.success(`'${nameForm}' успішно видалено.`);
    
  };


// --------Відображення загальної інформації про формуляр
export const getFormulyarInfo = async (idForm) => {
  dispatch(setLoading(true));
    const f={        
      'nameContract': 'FORSAG-001',
      'idContract': '147869',
      'dateContract': '2022-01-16', 
      'namePidprOwner': 'ПП Транссвіт',
      'codePidprOwner': '31691350',
      'nameStationFrom': 'Прилуки',
      'codeStationFrom': '429002',
      'nameElevator': 'СТОВ "ІНТЕР"',
      'codeElevator': '30148677',
      'nameStationTo': 'Свіноуйсьце',
      'codeStationTo': '610147',
      'namePidprSaller': 'ЗАТ "ІМЛІТЕКС АГРО"',
      'codePidprSaller': '35919521',
      'countCar': '54',
      'nameCargo': 'кукурудза',
      'codeCargo': '15006',
      'weight': '3780',
      'dateBegin': '2022-06-01',
      'codeState': 1
    };
    dispatch(setFormulyarInfo(f));
    dispatch(setLoading(false));
};

// --------Відображення планових подій до формуляру
export const getFormulyarPlan = async (idForm) => {
  dispatch(setLoading(true));
    const f=[{        
      'idAction': '1',
      'idDicAction': '3',
      'nameAction': 'Бронювання потужностей елеватору щодо накопичення зернових у пунктах відправлення',
      'canApproval' : '1',
      'idAgreeExp': '',
      'numberAgreeExp': '', 
      'nameAgreeExp': '',
      'p1AgreeExp': '', 
      'p2AgreeExp': '',       
      'nameServiceExp': '',
      'idAgree': '145',
      'numberAgree': '20', 
      'nameAgree': '',
      'p1Agree': 'ТОВ "ФОРСАЖ"', 
      'p2Agree': 'СТОВ "ІНТЕР"',       
      'nameService': 'збереження вантажу',        
      'datePlanBeg': '2022-05-01',
      'datePlanEnd': '2022-05-02',
      'codeState': '4',
      'idOpersFact': '4',
      'dateFact': '2022-05-02'        
      },
      {        
        'idAction': '2',
        'idDicAction': '4',
        'nameAction': 'Бронювання потужностей портового елеватору та послуг стивідирної компанії',
        'canApproval' : '1',
        'idAgreeExp': '',
        'numberAgreeExp': '', 
        'nameAgreeExp': '',
        'p1AgreeExp': '', 
        'p2AgreeExp': '',       
        'nameServiceExp': '',
        'idAgree': '146',
        'numberAgree': '60', 
        'nameAgree': '',
        'p1Agree': 'ТОВ «Кофко»', 
        'p2Agree': 'ТОВ "ДССК"',       
        'nameService': 'поглинання вантажу',        
        'datePlanBeg': '2022-05-10',
        'datePlanEnd': '2022-05-11',
        'codeState': '2',
        'idOpersFact': '',
        'dateFact': ''             
        },
        {        
          'idAction': '3',
          'idDicAction': '5',
          'nameAction': 'Узгодження оренди або користування вагонами з власником вагонів (не потрібно за умови використання вагонів ЦТЛ)',
          'canApproval' : '1',
          'idAgreeExp': '',
          'numberAgreeExp': '', 
          'nameAgreeExp': '',
          'p1AgreeExp': '', 
          'p2AgreeExp': '',       
          'nameServiceExp': '',
          'idAgree': '',
          'numberAgree': '', 
          'nameAgree': '',
          'p1Agree': '', 
          'p2Agree': '',       
          'nameService': '',        
          'datePlanBeg': '',
          'datePlanEnd': '',
          'codeState': '',
          'idOpersFact': '',
          'dateFact': ''             
        },
        {        
          'idAction': '4',
          'idDicAction': '7',
          'nameAction': 'Підсилка порожніх вагонів',
          'canApproval' : '1',
          'idAgreeExp': '22',
          'numberAgreeExp': '50', 
          'nameAgreeExp': '',
          'p1AgreeExp': 'ТОВ "ФОРСАЖ"', 
          'p2AgreeExp': 'ІТХАБ',       
          'nameServiceExp': 'Забезпечення порож вагонів',
          'idAgree': '147',
          'numberAgree': '90', 
          'nameAgree': '',
          'p1Agree': 'ІТХАБ', 
          'p2Agree': 'ТОВ «Укрзалізниця» станція Ічня',       
          'nameService': 'Забезпечення порож вагонів',        
          'datePlanBeg': '2022-05-03',
          'datePlanEnd': '2022-05-03',
          'codeState': '5',
          'idOpersFact': '',
          'dateFact': ''             
        },
        
        {        
          'idAction': '5',
          'idDicAction': '8',
          'nameAction': 'Подача порожніх вагонів на під’їзну колію',
          'canApproval' : '1',
          'idAgreeExp': '',
          'numberAgreeExp': '', 
          'nameAgreeExp': '',
          'p1AgreeExp': '', 
          'p2AgreeExp': '',       
          'nameServiceExp': '',
          'idAgree': '148',
          'numberAgree': '40', 
          'nameAgree': '',
          'p1Agree': 'ІТХАБ', 
          'p2Agree': 'ТОВ «Укрзалізниця» станція Ічня',       
          'nameService': 'Подача порож. вагонів',        
          'datePlanBeg': '2022-05-03',
          'datePlanEnd': '2022-05-03',
          'codeState': '1',
          'idOpersFact': '',
          'dateFact': ''             
        },
    ];
    dispatch(setFormulyarPlan(f));
    dispatch(setLoading(false));
};

// --------видалення планової події
export const deletePlanAction = (idAction, nameAction) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити планову подію '${nameAction}'?`,
    onSubmit: () => submitDeletePlanAction(idAction, nameAction),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeletePlanAction = async (idAction, nameAction) => {  
      showConfirmationModal({ isOpen: false });
      toast.success(`Планову '${nameAction}' подію успішно видалено.`);
    
  };

// --------класифікатор подій 

export const getDicActions = async () => {
  const f=[
    {
      'value': '1',
      'label': 'Узгодження/підписання договору про постачання з одержувачем (замовником)',
      'canApproval':  1
    },
    {
      'value': '2',
      'label': 'Узгодження/підписання договору про постачання з відправником (власником вантажу)',
      'canApproval':  1
    },
    {
      'value': '3',
      'label': 'Бронювання потужностей елеватору щодо накопичення зернових у пунктах відправлення',
      'canApproval':  0
    },
    {
      'value': '4',
      'label': 'Бронювання потужностей портового елеватору та послуг стивідирної компанії',
      'canApproval':  0
    },
    {
      'value': '5',
      'label': 'Узгодження оренди або користування вагонами з власником вагонів (не потрібно за умови використання вагонів ЦТЛ)',
      'canApproval':  1
    },      
    {
      'value': '7',
      'label': 'Підсилка порожніх вагонів',
      'canApproval':  1
    },      
    {
      'value': '8',
      'label': 'Подача порожніх вагонів на під’їзну колію',
      'canApproval':  1
    }
    ,      
    {
      'value': '9',
      'label': 'Навантаження',
      'canApproval':  1
    }
  ];
  dispatch(setDicActions(f));
};



// --------НДІ Контракти  
export const getDicContracts = async (enterpriseId) => {
  await axios.get(`api-vantazh/contracts/get_all/${enterpriseId}/0/100`)
    .then(res => {
      if(res.status === 200) {
        const newDataTitle = [];        
        res.data.forEach(item => { 
          newDataTitle.push({'value': item.value, 'title': item.title})                  
        });                     
        dispatch(setDicContracts(newDataTitle));                               
      }
  })  
};

// --------НДІ Партії вантажу 
  export const getDicCargoParts = async (enterpriseId) => {
    const f=[{
      'value': '3331',
      'title': 'партія кукрудзи (3780 т) від ст. Прилуки'
      }
    ];
    dispatch(setDicCargoParts(f));
  };
// --------НДІ RLC 
export const getDicRLC = async () => {
  const f=[{
    'value': '1',
    'title': 'Прилуки Півд. – Ізов Льв. – Воля-Барановська ПКП ЛХС – Свіноуйсьце ПКП Карго КУКУРУЗА'
    }
  ];
  dispatch(setDicRLC(f));
};



  export const getPlanAction = async (idAction) => {
    dispatch(setLoading(true));
      const f={        
        'idAction': '1',
        'idContract': '147869',
        'idCargoParts': '2',
        'idForm': '111',
        'codeAction': '3', 
        'idAgreeExp': '',
        'codeServiceExp': '',
        'idAgree': '145',
        'codeService': '6',
        'datePlanBeg': '2022-05-01',
        'datePlanEnd': '2022-05-02',
        'codeState': '1',
        'idOpersFact': '1',
        'dateFact': '2022-05-02'        
        };
      dispatch(setPlanAction(f));
      dispatch(setLoading(false));
  };

  

  export const openPlanActionModal = async (isOpen, planAction = {}) => {
    const f={        
      'idAction': '1',
      'idContract': '147869',
      'idCargoParts': '2',
      'idForm': '111',
      'codeAction': '3', 
      'idAgreeExp': '',
      'codeServiceExp': '',
      'idAgree': '145',
      'codeService': '6',
      'datePlanBeg': '2022-05-01',
      'datePlanEnd': '2022-05-02',
      'codeState': '1',
      'idOpersFact': '1',
      'dateFact': '2022-05-02'        
      };
      dispatch(setPlanAction(f));
      dispatch(setPlanActionModal(isOpen));
    };

// --------НДІ Вантаж
export const getDicCargo = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_MESPLAN_NGR&titleOut=name&valueOut=code&all=true`)
  .then(res => {
    if(res.status === 200) {
      dispatch(setDicCargo(res.data));
    } 
  }) 
};
/*
const f=[{
  'value': '11005',
  'title': 'Пшениця'
  },
  {
    'value': '12008',
    'title': 'Жито'
  },
  {
    'value': '15006',
    'title': 'Кукурудза'
  }
];
*/
// --------НДІ Країни
export const getDicCountries = async () => {
  const f=[{
    'value': '804',
    'title': 'Україна'
    },
    {
      'value': '616',
      'title': 'Польща'
    }
  ];
  dispatch(setDicCountries(f));
};

// --------НДІ Станції
export const getDicStations = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU_${localStorage.getItem('i18nextLng')}&valueOut=K_RP&all=true`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicStations(res.data));
      }
    })
};


  // --------Події
export const getFormulyarActions = async (idForm) => {
  dispatch(setLoading(true));
    const f=[{        
      'idAction': '1',
      'idRLC': '1',
      'order': '1',
      'codeAction': '5',
      'nameAction': 'Узгодження оренди або використання вагонів з власником вагонів', 
      'codeCountry': '804',
      'nameCountry': 'Україна',
      'codeStation': '',
      'nameStation': '',
      'description': 'Оренда вагонів 1520',
      'services': [{
                      'idService': '1',
                      'idAction': '1',
                      'idRLC': '1',
                      'order': '1',
                      'codeService': '14',
                      'isKey': '0',
                      'nameService': 'оренда вагонів',
                      'codeObjSum': '4',
                      'nameObjSum': 'вагоно/доба',
                      'price': '700',
                      'codeObjDuration': '1',
                      'nameObjDuration': 'вагон',
                      'duraction': '0',
                      'allPrice': '378000',
                      'allDuraction': '0',
                      'executors': [
                                    {
                                      'idExecutor' : '1',
                                      'idAgreement' : '1',
                                      'numberAgreement': '',
                                      'order': '1',
                                      'idServiceSaler': '',
                                      'nameServiceSaler': 'ТОВ "УКРТРАНСЛОДЖИСТІК"',
                                      'idServiceCustomer': '',
                                      'nameServiceCustomer': 'ПП Транссвіт',
                                      'agreementPrice': '700',
                                      'allPrice': '378000',
                                      'dateBegin': '',
                                      'dateEnd': ''
                                    }
                      ]
                  }]             
      },
      {        
      'idAction': '2',
      'idRLC': '1',
      'order': '2',
      'codeAction': '7',
      'nameAction': 'Підсилка порожніх вагонів', 
      'codeCountry': '804',
      'nameCountry': 'Україна',
      'codeStation': '429002',
      'nameStation': 'Прилуки',
      'description': 'Підсилка порожніх вагонів 1520 до станції Прилуки',
      'services': [{
                      'idService': '2',
                      'idAction': '2',
                      'idRLC': '1',
                      'order': '1',
                      'codeService': '11',
                      'isKey': '0',
                      'nameService': 'пошук та надання вагонного парку',
                      'codeObjSum': '1',
                      'nameObjSum': 'вагон',
                      'price': '3500',
                      'codeObjDuration': '7',
                      'nameObjDuration': 'поїзд',
                      'duraction': '48',
                      'allPrice': '189000',
                      'allDuraction': '48',
                      'executors': [
                                    {
                                      'idExecutor' : '2',
                                      'idAgreement' : '1',
                                      'numberAgreement': '',
                                      'order': '1',
                                      'idServiceSaler': '',
                                      'nameServiceSaler': 'ТОВ "УКРТРАНСЛОДЖИСТІК"',
                                      'idServiceCustomer': '',
                                      'nameServiceCustomer': 'ПП Транссвіт',
                                      'agreementPrice': '3500',
                                      'allPrice': '378000',
                                      'dateBegin': '',
                                      'dateEnd': ''
                                    }
                                  ]
                  }]                       
      },
      {        
      'idAction': '3',
      'idRLC': '1',
      'order': '3',
      'codeAction': '8',
      'nameAction': 'Подача порожніх вагонів на під’їзну колію', 
      'codeCountry': '804',
      'nameCountry': 'Україна',
      'codeStation': '429002',
      'nameStation': 'Прилуки',
      'description': 'Подавання порожніх вагонів 1520 на п/к',      
      'services': [{
                      'idService': '3',
                      'idAction': '3',
                      'idRLC': '1',
                      'order': '1',
                      'codeService': '1',
                      'isKey': '0',
                      'nameService': 'подавання порожніх вагонів під навантаження на під`їзну колію',
                      'codeObjSum': '1',
                      'nameObjSum': 'група',
                      'price': '300',
                      'codeObjDuration': '1',
                      'nameObjDuration': 'група',
                      'duraction': '0.5',
                      'allPrice': '900',
                      'allDuraction': '1.5',
                      'executors': [
                        {
                          'idExecutor' : '3',
                          'idAgreement' : '2',
                          'numberAgreement': '',
                          'order': '1',
                          'idServiceSaler': '',
                          'nameServiceSaler': 'Прилуцька філія ПрАТ МППЗТ',
                          'idServiceCustomer': '',
                          'nameServiceCustomer': 'ПП Транссвіт',
                          'agreementPrice': '300',
                          'allPrice': '900',
                          'dateBegin': '',
                          'dateEnd': '2022-06-08'
                        }
                      ]
                  }]                              
      },
      {        
      'idAction': '4',
      'idRLC': '1',
      'order': '4',
      'codeAction': '9',
      'nameAction': 'Оформлення перевізного документу, навантаження', 
      'codeCountry': '804',
      'nameCountry': 'Україна',
      'codeStation': '429002',
      'nameStation': 'Прилуки',
      'description': 'Навантаження, оформлення перевізного документа (ПД)',
      'services': [{
                    'idService': '4',
                    'idAction': '4',
                    'idRLC': '1',
                    'order': '1',
                    'codeService': '2',
                    'isKey': '0',
                    'nameService': 'навантаження порожніх вагонів на під`їзній колії',
                    'codeObjSum': '2',
                    'nameObjSum': 'вагон',
                    'price': '0',
                    'codeObjDuration': '2',
                    'nameObjDuration': 'вагон',
                    'duraction': '0.5',
                    'allPrice': '0',
                    'allDuraction': '27',
                    'executors': [
                                    {
                                      'idExecutor' : '4',
                                      'idAgreement' : '3',
                                      'numberAgreement': '',
                                      'order': '1',
                                      'idServiceSaler': '',
                                      'nameServiceSaler': 'ТОВ Агрофірма Лосинівська',
                                      'idServiceCustomer': '',
                                      'nameServiceCustomer': 'ПП Транссвіт',
                                      'agreementPrice': '0',
                                      'allPrice': '0',
                                      'dateBegin': '',
                                      'dateEnd': ''
                                    }
                                  ]
                },
                {
                  'idService': '5',
                  'idAction': '4',
                  'idRLC': '1',
                  'order': '2',
                  'codeService': '31',
                  'isKey': '0',
                  'nameService': 'зважування на вагах УЗ',
                  'codeObjSum': '1',
                  'nameObjSum': 'вагон',
                  'price': '80',
                  'codeObjDuration': '1',
                  'nameObjDuration': 'вагон',
                  'duraction': '0.1',
                  'allPrice': '4320',
                  'allDuraction': '5.4',
                  'executors': [
                                {
                                  'idExecutor' : '5',
                                  'idAgreement' : '4',
                                  'numberAgreement': '',
                                  'order': '1',
                                  'idServiceSaler': '',
                                  'nameServiceSaler': 'ТОВ Агрофірма Лосинівська',
                                  'idServiceCustomer': '',
                                  'nameServiceCustomer': 'ПП Транссвіт',
                                  'agreementPrice': '0',
                                  'allPrice': '0',
                                  'dateBegin': '',
                                  'dateEnd': ''
                                }
                              ]
              },
              {
                'idService': '6',
                'idAction': '4',
                'idRLC': '1',
                'order': '3',
                'codeService': '17',
                'isKey': '0',
                'nameService': 'встановлення ЗПП на вагони та передача інформації для оформлення ПД',
                'codeObjSum': '1',
                'nameObjSum': 'вагон',
                'price': '50',
                'codeObjDuration': '1',
                'nameObjDuration': 'вагон',
                'duraction': '0.1',
                'allPrice': '2700',
                'allDuraction': '5.4',
                'executors': [
                              {
                                'idExecutor' : '6',
                                'idAgreement' : '5',
                                'numberAgreement': '',
                                'order': '1',
                                'idServiceSaler': '',
                                'nameServiceSaler': 'АТ "УЗ" ф. "ВТП"',
                                'idServiceCustomer': '',
                                'nameServiceCustomer': 'ПП Транссвіт',
                                'agreementPrice': '50',
                                'allPrice': '2700',
                                'dateBegin': '',
                                'dateEnd': ''
                              }
                            ]
            },
            {
              'idService': '7',
              'idAction': '4',
              'idRLC': '1',
              'order': '4',
              'codeService': '8',
              'isKey': '0',
              'nameService': 'розрахунок платежів за перевезення (оформлення ПД)',
              'codeObjSum': '3',
              'nameObjSum': 'ПД',
              'price': '15',
              'codeObjDuration': '3',
              'nameObjDuration': 'ПД',
              'duraction': '0.5',
              'allPrice': '30',
              'allDuraction': '1',
              'executors': [
                            {
                              'idExecutor' : '7',
                              'idAgreement' : '6',
                              'numberAgreement': '',
                              'order': '1',
                              'idServiceSaler': '',
                              'nameServiceSaler': 'ТОВ "УКРАЗІЯТРАНС"',
                              'idServiceCustomer': '',
                              'nameServiceCustomer': 'ЗАТ "ІМЛІТЕКС АГРО"',
                              'agreementPrice': '15',
                              'allPrice': '30',
                              'dateBegin': '',
                              'dateEnd': ''
                            }
                          ]
          },
          {
            'idService': '8',
            'idAction': '4',
            'idRLC': '1',
            'order': '5',
            'codeService': '28',
            'isKey': '0',
            'nameService': 'надання ветеринарного свідоцтва',
            'codeObjSum': '3',
            'nameObjSum': 'документ',
            'price': '1300',
            'codeObjDuration': '3',
            'nameObjDuration': 'документ',
            'duraction': '2',
            'allPrice': '2600',
            'allDuraction': '4',
            'executors': [
                          {
                            'idExecutor' : '8',
                            'idAgreement' : '7',
                            'numberAgreement': '',
                            'order': '1',
                            'idServiceSaler': '',
                            'nameServiceSaler': 'ТОВ "УКРАЗІЯТРАНС"',
                            'idServiceCustomer': '',
                            'nameServiceCustomer': 'ЗАТ "ІМЛІТЕКС АГРО"',
                            'agreementPrice': '1300',
                            'allPrice': '2600',
                            'dateBegin': '',
                            'dateEnd': ''
                          }
                        ]
        }]                      
      }

    ];
    dispatch(setFormulyarActions(f));
    dispatch(setLoading(false));
};

export const persistActions = async (actions) =>{
  dispatch(setFormulyarActions(actions));    
}

    
  

