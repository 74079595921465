// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_WS = process.env.REACT_APP_HOST_WS || '';

export const CONFIG_MESPLAN = {
  nsiUrl: process.env.REACT_APP_NSI_URL, 
  apiUrl: process.env.REACT_APP_API_GATEWAY_URL, 
};
export const HOST_DOC = process.env.REACT_APP_DOC_URL || '';
export const HOST_VAG = process.env.REACT_APP_VAG_URL || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;
export const MAPBOX_API2 = process.env.REACT_APP_MAPBOX2;


// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.cabinet; // as '/dashboard/cabinet'

// LAYOUT
// ----------------------------------------------------------------------

export const NAVBAR = {
  BASE_WIDTH: 260,
  // DASHBOARD_WIDTH: 280,
  DASHBOARD_WIDTH: 420,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_WIDTH_MENU: 330,
  DASHBOARD_COLLAPSE_WIDTH_MENU: 138,  
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 40,
  DASHBOARD_ITEM_SUB_HEIGHT: 36,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
  MOBILE_HEIGHT: 64,
  MOBILE_PADDING_BOTTOM: 20,
  MAIN_DESKTOP_HEIGHT: 70,
  DASHBOARD_DESKTOP_HEIGHT: 75,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 75 - 32,
  DASHBOARD_PADDING_BOTTOM: 10
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 26,
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};


// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'vertical',
  themeStretch: false,
  openSetting: false
};
