import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
import { customDateFormat, customPointDateFormat } from '../../utils/formatTime';
import { DateFormat } from '../../utils/timeConverter'
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';



const initialState = {
    isLoading: false,    
    error: null,
    cargoParts: [],
    cargoPart: null,
    dicStates: [],
    dicPidprElevator: [],
    dicStationsUkr: [],
    dicContracts: [],

    isOpenCargoPartModal: false,
    isOpenCargoPartViewModal: false,
    maxCarrying: []
  };

const slice = createSlice({
    name: 'cargoPart',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
        state.isLoading = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
        setDicStates(state, action) {
          state.dicStates = action.payload;
        },
        setCargoParts(state, action) {
        state.cargoParts = action.payload;
        },        
        setCargoPart(state, action) {
        state.cargoPart = action.payload;
        },
        setDicPidprElevator(state, action) {
            state.dicPidprElevator = action.payload;
        },      
        setDicStationsUkr(state, action) {
            state.dicStationsUkr = action.payload;
        }, 
        setDicContracts(state, action) {
            state.dicContracts = action.payload;
        },        
        setOpenCargoPartModal(state, action) {
          state.isOpenCargoPartModal = action.payload;
        },               
        setOpenCargoPartViewModal(state, action) {
          state.isOpenCargoPartViewModal = action.payload;
        },
        setMaxCarrying(state, action) {
          state.maxCarrying = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,    
    hasError,    
    setDicStates,
    setCargoParts,
    setCargoPart,
    setDicPidprElevator,
    setDicStationsUkr,
    setDicContracts,   

    setOpenCargoPartModal,
    setOpenCargoPartViewModal,
    setMaxCarrying
  } = slice.actions;


// --------класифікатор станів
export const getDicStates = async () => {  
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name_${localStorage.getItem('i18nextLng')}&valueOut=status&all=true`)
  .then(res => {
    if(res.status === 200) {
      const newDataLabel = [];        
      res.data.forEach(item => { 
        newDataLabel.push({'value': item.value, 'label': item.title})                  
      });         
      dispatch(setDicStates(newDataLabel));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {    
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });    
}; 

// --------Реєстр партій вантажу (все по предприятию)
/*
export const getCargoParts = async (enterpriseId) => {
  
  dispatch(setLoading(true));
      // await axios.get(`api-vantazh/contracts/get_all/${enterpriseId}/0/100`)
        .then(res => {
          if(res.status === 200) {            
            dispatch(setCargoParts(res.data));                               
          }
    })    
    .finally(() => dispatch(setLoading(false)) );    
};
*/

export const getCargoParts = async ( /* enterpriseId */ ) => {
  /*
    dispatch(setLoading(true));
      const f=[{
        'idContract': 333,
        'nameContract': '2804 Corn',
        'dateContract': '2022-06-01T00:00:00',
        'idPart': 3331,
        'orderPart': 0,
        'namePart': 'Кукуруза Прилуки-Свиноуйсьце',
        'nameStationFrom': 'Прилуки',
        'codeStationFrom': 429002,
        'idElevator': 232,
        'nameElevator': "ТОВ \"АГРОФІРМА \"ЛОСИНІВСЬКА\"", 
        "edrpouElevator": 14249542,       
        'weight': 3780,
         countCar: 54,
         distance: 1007,
         transitTime: 7,
         "dateBegin": "2022-07-01T00:00:00",
         "dateEnd": "2022-07-08T00:00:00",
        'codeState': 1
      }];
    
    dispatch(setCargoParts(f));
    dispatch(setLoading(false));
    */
    dispatch(setLoading(true));    
    // await axios.get(`api-vantazh/contracts/Parts?enterpriseId=${enterpriseId}`)
    await axios.get(`api-vantazh/contracts/Parts`)
      .then(res => {
        if(res.status === 200) {  
          console.log('getCargoParts', res.data); 
          /*
          let newItems = [...res.data];   
            newItems = newItems.map(item => {
                const newItem = {
                  codeState: item.codeState,
                  codeStationFrom: item.codeStationFrom,
                  countCar: item.countCar,
                  dateBegin: item.dateBegin,
                  dateContract: item.dateContract,
                  dateEnd: item.dateEnd,
                  distance: item.distance,
                  edrpouElevator: item.edrpouElevator,
                  idContract: item.idContract,
                  idElevator: item.idElevator,
                  idPart: item.idPart,
                  nameContract: item.nameContract,
                  nameElevator: item.nameElevator,
                  namePart: item.namePart, 
                  nameStationFrom: item.nameStationFrom, 
                  tidPart: `${item.nameStationFrom} - ${item.weight}т - ${customPointDateFormat(item.dateBegin)}`,
                  transitTime: item.transitTime,                                      
                  weight: item.weight
                }
                return newItem;
            });
            // console.log('newItems', newItems);  
            */       
          dispatch(setCargoParts( res.data /* newItems */));                               
        }
        else {
          throw new Error(res.statusText || res.status);          
        }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })    
    .finally(() => dispatch(setLoading(false)) );
  };

// --------Реєстр партій вантажу (по ID контракта)

export const getCargoPartsByContract = async (idContract) => {
  
  dispatch(setLoading(true));
      await axios.get(`api-vantazh/contracts/Parts?idContract=${idContract}`)
        .then(res => {
          if(res.status === 200) { 
            console.log('getCargoPartsByContract', res.data); 
            /* 
            let newItems = [...res.data];   
            newItems = newItems.map(item => {
                const newItem = {
                  codeState: item.codeState,
                  codeStationFrom: item.codeStationFrom,
                  countCar: item.countCar,
                  dateBegin: item.dateBegin,
                  dateContract: item.dateContract,
                  dateEnd: item.dateEnd,
                  distance: item.distance,
                  edrpouElevator: item.edrpouElevator,
                  idContract: item.idContract,
                  idElevator: item.idElevator,
                  idPart: item.idPart,
                  nameContract: item.nameContract,
                  nameElevator: item.nameElevator,
                  namePart: item.namePart, 
                  nameStationFrom: item.nameStationFrom, 
                  tidPart: `${item.nameStationFrom} - ${item.weight}т - ${customPointDateFormat(item.dateBegin)}`,
                  transitTime: item.transitTime,                                      
                  weight: item.weight
                }
                return newItem;
            }); 
            */        
            dispatch(setCargoParts( res.data /* newItems */));                               
          }
          else {
            throw new Error(res.statusText || res.status);          
          }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })   
    .finally(() => dispatch(setLoading(false)) );    
};

/*
export const getCargoPartsByContract = async (idContract) => {
    dispatch(setLoading(true));
      const f=[{
        'idContract': 333,
        'nameContract': '2804 Corn',
        'dateContract': '2022-06-01T00:00:00',
        'idPart': 3331,
        'orderPart': 0,
        'namePart': 'партія вантажу',
        'nameStationFrom': 'Прилуки',
        'codeStationFrom': 429002,
        'idElevator': 232,
        'nameElevator': "ТОВ \"АГРОФІРМА \"ЛОСИНІВСЬКА\"", 
        "edrpouElevator": 14249542,           
        'weight': 3780,
         countCar: 54,
         distance: 1007,
         transitTime: 7,
         "dateBegin": "2022-07-01T00:00:00",
         "dateEnd": "2022-07-08T00:00:00",
        'codeState': 1
      }];
    
    dispatch(setCargoParts(f));
    dispatch(setLoading(false));
  };
*/
// --------видалення партії вантажу
export const deleteCargoPart = (idPart, name ) => {
    showConfirmationModal({
      isOpen: true,
      title: i18n.t('service.cargoParts.titleDelete'),
      content: `${i18n.t('service.cargoParts.answerDelete')} ${name}?`,
      onSubmit: () => submitDelete(idPart),
      onCancel: () => showConfirmationModal({ isOpen: false })
    });
  };
  
  const submitDelete = async (idPart) => { 
    await axios.delete(`/api-vantazh/contracts/Parts?idCargo=${idPart}`) 
    .then(res => {
      if(res.status === 200) {
        getCargoParts();
        showConfirmationModal({ isOpen: false });
        toast.success(i18n.t('service.cargoParts.deleted'));        
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });    
  };

// --------видалення партії вантажу для контракта
export const deleteCargoPartByContract = (idPart, name, idContract) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.cargoParts.titleDelete'),
    content: `${i18n.t('service.cargoParts.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteByContract(idPart, idContract),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteByContract = async (idPart, idContract) => { 
  await axios.delete(`/api-vantazh/contracts/Parts?idCargo=${idPart}`) 
  .then(res => {
    if(res.status === 200) {
      getCargoPartsByContract(idContract);
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.cargoParts.deleted'));      
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {
    console.log('deleteCargoPart error:', error);
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });     
};

// --------збереження
export const saveCargoPart = async (data) => {
  await axios.put(`api-vantazh/contracts/Parts`, data)
    .then(res => {
      if(res.status === 200) {
        getDicContracts(res.data.idPart);
        dispatch(setCargoPart(res.data));   
        toast.success(i18n.t('service.cargoParts.saved'));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження  (NEW)
export const saveNewCargoPart = async (data) => {
  await axios.post(`api-vantazh/contracts/Parts`, data)
    .then(res => {
      if(res.status === 200) {
        getDicContracts(res.data.idPart);
        dispatch(setCargoPart(res.data));   
        toast.success(i18n.t('service.cargoParts.saved'));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};


// --------партія вантажу  по ID Part

export const getCargoPart = async (idPart) => {
  /*
      const f={
        'idContract': 333,
        'nameContract': '2804 Corn',
        'dateContract': '2022-06-01T00:00:00',
        'idPart': 3331,
        'orderPart': 0,
        'namePart': 'Кукуруза Прилуки-Свиноуйсьце',
        'nameStationFrom': 'Прилуки',
        'codeStationFrom': '429002',
        'idElevator': 232,
        'nameElevator': "ТОВ \"АГРОФІРМА \"ЛОСИНІВСЬКА\"", 
        "edrpouElevator": 14249542,       
        'weight': 3780,
         countCar: 54,
         distance: 1007,
         transitTime: 7,
         "dateBegin": "2022-07-01T00:00:00",
         "dateEnd": "2022-07-08T00:00:00",
        'codeState': 1
      };
    
    dispatch(setCargoPart(f));
  */  
    // await axios.get(`api-vantazh/contracts/get_item/${idPart}`)
   await axios.get(`api-vantazh/contracts/Parts?idCargo=${idPart}`)
    .then(res => {
      if(res.status === 200) {
        console.log('getCargoPart', res.data);
        /*
          const newItem = {
              codeState: res.data.codeState,
              codeStationFrom: res.data.codeStationFrom,
              countCar: res.data.countCar,
              dateBegin: res.data.dateBegin,
              dateContract: res.data.dateContract,
              dateEnd: res.data.dateEnd,
              distance: res.data.distance,
              edrpouElevator: res.data.edrpouElevator,
              idContract: res.data.idContract,
              idElevator: res.data.idElevator,
              idPart: res.data.idPart,
              nameContract: res.data.nameContract,
              nameElevator: res.data.nameElevator,
              namePart: res.data.namePart, 
              nameStationFrom: res.data.nameStationFrom, 
              tidPart: `${res.data.nameStationFrom} - ${res.data.weight}т - ${customPointDateFormat(res.data.dateBegin)}`,
              transitTime: res.data.transitTime,                                      
              weight: res.data.weight
            }
        */      
        dispatch(setCargoPart( res.data /* newItem */));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });          
  };

  export const getNewCargoPart = async () => {
    dispatch(setCargoPart(null));  
  };
  

// --------НДІ Підприємства (елеватор)
export const getDicPidprElevator = async (idPart) => {
    // await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=views.V_Ekbs_Pidpr_All&titleOut=display_info&valueOut=id_vsp&&value=4&searchField=TYPE_PIDPR&all=false&limit=100`)
    await axios.get(`/api-vantazh/contracts/Parts/Elevators/${idPart}`)
      .then(res => {
        if(res.status === 200) {   
          console.log('getDicPidprElevator', res.data);       
          dispatch(setDicPidprElevator(res.data));       
        }
        else {
          throw new Error(res.statusText || res.status);          
        }  
      })
      .catch((error) => {        
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });  
      
};
// --------НДІ Станції
export const getDicStationsUkr = async () => {
    await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU_${localStorage.getItem('i18nextLng')}&valueOut=K_RP&&value=СТ&searchField=M_SPRPR&all=false&limit=2000`)
    // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU&valueOut=K_RP&all=true')
      .then(res => {
        if(res.status === 200) {
          // dispatch(setDicStationsUkr(res.data));
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value})`};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            }                 
          });         
          dispatch(setDicStationsUkr(newData));
        }
        else {
          throw new Error(res.statusText || res.status);          
        }  
      })  
      .catch((error) => {        
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });        
  };

  
  // --------НДІ Контракти (контракты, где Стороной 1(id_owner) либо Стороной 2(id_customer) является enterpriseId)
  // ---------и статус контракта = 1 или 2 ??????????????????????????????????
  export const getDicContracts = async (idPart) => {
    // console.log('getDicContracts idPart=', idPart);
    // await axios.get(`api-vantazh/contracts/ContractFord?enterpriseId=${enterpriseId}`)
    // await axios.get(`api-vantazh/contracts/Contract`)
    await axios.get(`api-vantazh/contracts/ContractsForSelect/${idPart}`)
        .then(res => {
          if(res.status === 200) {  
            console.log('getDicContracts res.data', res.data);                
            dispatch(setDicContracts(res.data)); 
          }
          else {
            throw new Error(res.statusText || res.status);          
          }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });     
  };


  export const openCargoPartModal = async (idCargoPart) => {
    getCargoPart(idCargoPart);
    dispatch(setOpenCargoPartModal(true));
  };
  export const closeCargoPartModal = async () => { 
    dispatch(setOpenCargoPartModal(false));
  };

  // ---------------------------------
  export const openCargoPartViewModal = async (idCargoPart) => {
    getCargoPart(idCargoPart);
    dispatch(setOpenCargoPartViewModal(true));
  };

  export const closeCargoPartViewModal = async () => { 
    dispatch(setOpenCargoPartViewModal(false));
  };

  // ---------------Получение максимальной загрузки вагона в зависимости от рода вагона (вида груза)
export const getMaxCarryingByCodeCargo = async (codeCargo) => { 
  
  const data =  `" SELECT viewRV.max_carrying as maxCarring, viewRV.min_carrying as minCarring FROM views.V_ROD_VAGS viewRV              ` +
  ` INNER JOIN nsi_ekbs.Dic_Cargo_Group_Vag dicGroup on viewRV.code_rps=dicGroup.rod_vag                ` +
  ` INNER JOIN nsi_ekbs.Dic_Cargo_Group_Diapaz dicDiapaz on dicDiapaz.code_group=dicGroup.code_group    ` +  
  ` WHERE ${codeCargo} BETWEEN dicDiapaz.min_cargo_code AND dicDiapaz.max_cargo_code "`;  
  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        console.log('getMaxCarryingByCodeCargo', res.data);        
        dispatch(setMaxCarrying(res.data));
      }
      else 
      {
        dispatch(setMaxCarrying([]));
        throw new Error(res.statusText || res.status);
      }
    })  
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });      
};
 
