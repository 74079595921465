import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// import i18n from '../../locales/i18n';
// actions
import { showConfirmationModal } from './confirmation';
import { loaderEnd, loaderStart } from './loader';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  templates: [],
  template: null,
  isSaveModalOpened: false,
  isSaveDocModalOpened: false,
  docxTypes: [],
  resourceTypes: [],
  jsonSampleData: [],
  documents: [],
  document: null,
  searchDocxParams: {
    name_document: '',
    page: 1,
    count: 10,
    id_resource: '',
    id_type_document: '',
    date_beg: '',
    date_end: '',
  },
  previewData: {
    templates: [],
    data: null,
  },
};

const constructorSlice = createSlice({
  name: 'constructor',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTemplates: (state, action) => ({
      ...state,
      templates: action.payload,
    }),
    setDocuments: (state, action) => ({
      ...state,
      documents: action.payload,
    }),
    setDocument: (state, action) => ({
      ...state,
      document: action.payload,
    }),
    setTemplate(state, action) {
      state.template = action.payload;
    },
    setModalOpen(state, action) {
      state.isSaveModalOpened = action.payload;
    },
    setDocModalOpen(state, action) {
      state.isSaveDocModalOpened = action.payload;
    },
    setDocxTypes: (state, action) => ({
      ...state,
      docxTypes: action.payload,
    }),
    setResourceTypes: (state, action) => ({
      ...state,
      resourceTypes: action.payload,
    }),
    setJsonSampleData: (state, action) => ({
      ...state,
      jsonSampleData: action.payload,
    }),
    setSearchDocxParams: (state, action) => ({
      ...state,
      searchDocxParams: action.payload,
    }),
    setPreviewData: (state, action) => ({
      ...state,
      previewData: action.payload,
    }),
    _resetPreviewData: (state) => ({
      ...state,
      previewData: {
        templates: [],
        data: null,
      },
    }),
  },
});

// Reducer
export default constructorSlice.reducer;

// Actions
export const {
  setLoading,
  setModalOpen,
  setDocModalOpen,
  setTemplate,
  setTemplates,
  setDocxTypes,
  setResourceTypes,
  setDocuments,
  setDocument,
  setSearchDocxParams,
  setPreviewData,
  _resetPreviewData,
} = constructorSlice.actions;

export const getPreviewData = (templates, data) => {
  dispatch(setPreviewData({ templates, data }));
};

export const resetPreviewData = () => {
  dispatch(_resetPreviewData());
};

export const openModal = (isOpen) => {
  dispatch(setModalOpen(isOpen));
};

export const openDocModal = (isOpen) => {
  dispatch(setDocModalOpen(isOpen));
};

export const getDocxTypes = async () => {
  await axios
    .get(
      `/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Doc_Type_Templates&titleOut=name&valueOut=id_type_tetemplate&all=true`
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(setDocxTypes(res.data));
      }
    });
};

export const getResourceTypes = async () => {
  await axios
    .get(
      `/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Pidpr_Type_Resources&titleOut=name_short_${localStorage.getItem('i18nextLng')}&valueOut=code_resource&all=true`
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(setResourceTypes(res.data));
      }
    });
};

export const createTemplate = async (data) => {
  await axios.post(`/api-sys/docs/templates`, data).then((res) => {
    if (res.status === 200) {
      dispatch(setModalOpen(false));
      toast.success('Шаблон створено!');
      getTemplates();
    }
  });
};

export const updateTemplate = async (data, callback) => {
  await axios.put(`/api-sys/docs/templates/${data.idrec}`, data).then((res) => {
    if (res.status === 200) {
      dispatch(setModalOpen(false));
      callback();
      toast.success('Шаблон оновлено!');
      getTemplates();
    }
  });
};

export const getTemplates = async () => {
  await axios.get(`/api-sys/docs/templates`).then((res) => {
    if (res.status === 200) {
      dispatch(setTemplates(res.data));
    }
  });
};

export const getTemplate = async (id) => {
  await axios.get(`/api-sys/docs/templates/${id}`).then((res) => {
    if (res.status === 200) {
      dispatch(setTemplate(false));
    }
  });
};

export const deleteTemplate = async (id, callback) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити шаблон №${id}`,
    onSubmit: () => submitDelete(id, callback),
    onCancel: () => showConfirmationModal({ isOpen: false }),
  });
};

const submitDelete = async (id, callback) => {
  await axios.delete(`/api-sys/docs/templates/${id}`).then((res) => {
    if (res.status === 200) {
      showConfirmationModal({ isOpen: false });
      callback();
      toast.success('Шаблон успішно видалено.');
      getTemplates();
    }
  });
};

export const getDocuments = async (params) => {
  loaderStart();
  const {
    name_document: name,
    page,
    count,
    id_resource: resourceType,
    id_type_document: docType,
    date_beg: dateBeg,
    date_end: dateEnd,
  } = params;
  await axios
    .get(
      `/api-sys/docs/archives?name_document=${name}&page=${page}&count=${count}&id_resource=${resourceType}&id_type_document=${docType}&date_beg=${dateBeg}&date_end=${dateEnd}`
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(setDocuments(res.data));
        dispatch(setSearchDocxParams(params));
      }
    });
  loaderEnd();
};

export const getDocument = async (id) => {
  loaderStart();
  await axios.get(`/api-sys/docs/archives/${id}`).then((res) => {
    if (res.status === 200) {
      dispatch(setDocument(res.data));
    }
  });
  loaderEnd();
};

export const createDocument = async (data) => {
  await axios.post(`/api-sys/docs/archives`, data).then((res) => {
    if (res.status === 200) {
      dispatch(setDocModalOpen(false));
      // toast.success('Документ створено!')
      // getTemplates()
    }
  });
};

export const deleteDocument = async (id, callback) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити документ №${id}`,
    onSubmit: () => submitDeleteDoc(id, callback),
    onCancel: () => showConfirmationModal({ isOpen: false }),
  });
};

const submitDeleteDoc = async (id, callback) => {
  await axios.delete(`/api-sys/docs/archives/${id}`).then((res) => {
    if (res.status === 200) {
      showConfirmationModal({ isOpen: false });
      callback();
      toast.success('Документ успішно видалено.');
    }
  });
};
